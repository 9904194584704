import { useState, useContext } from 'react';
import {
  QubeListButtonContainer,
  QubeListButton,
  QubeListDropdown,
  QubeListContainer,
} from './qube-list.style';
import QubeItem from 'app/components/qube-item/qube-item';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import QubeCard from 'app/components/qube-card/qube-card';
import AccountStore from 'app/modules/account/account.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { AuthType } from 'app/modules/account/account.context.d';

/**
 * 
 * @param qubes 
 * @returns 
 */
const renderQubes = (qubes: QubeDeviceType[], onClick: (qube: QubeDeviceType) => void) => {
  return qubes.map((qube) => {
    return (
      <QubeItem qube={qube} key={qube.serial} onClick={() => onClick(qube)} />
    );
  });
};

interface Props {
  qubes: QubeDeviceType[];
}

/**
 * 
 * @returns 
 */
const QubeList = (props: Props) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [selected, setSelected] = useState<QubeDeviceType>();
  const accountContext = useContext(AccountStore);
  const inspectionContext = useContext(InspectionStore);
  const auth = accountContext.state.auth as AuthType;

  /**
   * 
   */
  const handleOpen = () => {
    setOpened(!opened);

    sessionStorage.removeItem('cluster');
    sessionStorage.removeItem('cluster-qube');
    const eventTargetElementElement = document.querySelector('div.GT-QUBE-CLUSTER.active') as HTMLElement;
    inspectionContext.state.map?.setOptions({ scrollwheel: true });
    if (eventTargetElementElement) {
      const content = eventTargetElementElement;
      const current = content.querySelector(`div.content.active`);
      content?.classList.remove('active');
      current?.classList.remove('active');
      content.querySelector('.qube-list-content')?.classList.remove('close');
      content.querySelector('.return-to-list')?.classList.remove('show');
      return;
    }
  };
  
  return (
    <QubeListButtonContainer
      onBlur={(event: any) => {
        if (
          event.relatedTarget?.id === 'gt_qubes_dropdown' ||
          event.relatedTarget?.classList.contains('gt_qube_card') ||
          event.relatedTarget?.classList.contains('no-close-dropdown')
        ) return;
        
        setOpened(false);
        setSelected(undefined);
      }}
    >
      <QubeListButton className={opened ? 'no-close-dropdown btn_red' : 'no-close-dropdown btn_default'} data-testid="qube-list-button" $opened={opened} onClick={handleOpen}>
        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.7001 12.1C14.7001 12.404 14.5321 12.668 14.2761 12.804L7.95605 16.356C7.82805 16.452 7.66805 16.5 7.50005 16.5C7.33205 16.5 7.17205 16.452 7.04405 16.356L0.724052 12.804C0.595796 12.7366 0.488462 12.6353 0.41371 12.5112C0.338959 12.3871 0.299649 12.2449 0.300052 12.1V4.9C0.300052 4.596 0.468052 4.332 0.724052 4.196L7.04405 0.644C7.17205 0.548 7.33205 0.5 7.50005 0.5C7.66805 0.5 7.82805 0.548 7.95605 0.644L14.2761 4.196C14.5321 4.332 14.7001 4.596 14.7001 4.9V12.1ZM7.50005 2.22L2.73205 4.9L7.50005 7.58L12.2681 4.9L7.50005 2.22ZM1.90005 11.628L6.70005 14.332V8.964L1.90005 6.268V11.628ZM13.1001 11.628V6.268L8.30005 8.964V14.332L13.1001 11.628Z" fill="white"/>
        </svg>
      </QubeListButton>
      
      {
        opened ? (
          <QubeListDropdown tabIndex={0} id="gt_qubes_dropdown" onClick={(e) => e.stopPropagation()}>
            {
              !selected ? (
                <>
                  <header>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.2 12.1C15.2 12.404 15.032 12.668 14.776 12.804L8.45601 16.356C8.32801 16.452 8.16801 16.5 8.00001 16.5C7.83201 16.5 7.67201 16.452 7.54401 16.356L1.22401 12.804C1.09575 12.7366 0.988416 12.6353 0.913665 12.5112C0.838913 12.3871 0.799604 12.2449 0.800006 12.1V4.9C0.800006 4.596 0.968006 4.332 1.22401 4.196L7.54401 0.644C7.67201 0.548 7.83201 0.5 8.00001 0.5C8.16801 0.5 8.32801 0.548 8.45601 0.644L14.776 4.196C15.032 4.332 15.2 4.596 15.2 4.9V12.1ZM8.00001 2.22L3.23201 4.9L8.00001 7.58L12.768 4.9L8.00001 2.22ZM2.40001 11.628L7.20001 14.332V8.964L2.40001 6.268V11.628ZM13.6 11.628V6.268L8.80001 8.964V14.332L13.6 11.628Z" fill="#D30000"/>
                    </svg>
                    You have <strong>&nbsp;{props.qubes.length} devices&nbsp;</strong> in this page
                  </header>
                  <QubeListContainer>{renderQubes(props.qubes, setSelected)}</QubeListContainer>
                </>
              ) : null
            }

            {
              selected ? (
                <QubeCard
                  isObserver={inspectionContext.state.permission_type === 'observer'}
                  auth={auth}
                  qube={selected}
                  onClose={() => setSelected(undefined)}
                />
              ) : null
            }
          </QubeListDropdown>
        ) : null
      }
    </QubeListButtonContainer>
  );
};

export default QubeList;
 