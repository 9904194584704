import { useContext, useEffect, useMemo, useState } from "react";
import { QubeModalFooter, QubeModalPagination, QubeModalWrapper } from './mobile-qube-modal.style';
import InspectionStore from "app/modules/inspection/inspection.context";
import { captureException } from "@sentry/react";
import { QubeDeviceType } from "app/modules/qube/qube.interfaces";
import { Loader } from "@googlemaps/js-api-loader";
import { GOOGLE_MAPS_LOADER_PARAMETERS } from "app/components/common/map/map.controller";
import { createQubeMarkerOverlay } from "app/components/common/map/modules/run/components/qube-marker/qube-marker-html-creators";
import AccountStore from "app/modules/account/account.context";
import { AuthType } from "app/modules/account/account.context.d";
import QubeCard from "app/components/qube-card/qube-card";

// google map settings
const GOOGLE_MAP_ID = '90f559dd3d1ba78f';
const DEFAULT_GOOGLE_MAP_OPTIONS = {
  mapId: GOOGLE_MAP_ID,
  zoom: 13,
  disableDefaultUI: false,
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControl: false,
  disableDoubleClickZoom: true,
  scrollwheel: false,
  draggable: false,
};

/**
 * 
 * @param device 
 * @param token 
 * @param map 
 */
const createMarker = async (device, token, map, setMarker) => {
  let AdvancedMarkerElement;

  try {
    const markerLib = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    AdvancedMarkerElement = markerLib.AdvancedMarkerElement as google.maps.MarkerLibrary['AdvancedMarkerElement'];
  } catch (err) {
    captureException(err);
  }

  const { contentEl } = createQubeMarkerOverlay(
    device,
    token,
    true,
    false,
    () => {},
    undefined,
    undefined,
    false,
  );

  const marker = new AdvancedMarkerElement({
    map,
    gmpClickable: true,
    content: contentEl,
    zIndex: 9,
  });

  if (device.position && !device.geolocked) {
    marker.map = map;
  }
  
  
  marker.getPosition = () => ({ lat: () => device.position?.coordinates[1], lng: () => device.position?.coordinates[0] });
  marker.getVisible = () => true;
  marker.data = { qube: device, opened: false };

  setMarker(marker);
};

/**
 *
 * @param props
 * @returns
 */
const QubeModal = () => {
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const clusterLength = inspectionContext.state.mobile.selected_cluster_markers.length;
  const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerClickEvent | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const qube = useMemo(() => {
    const device = inspectionContext.run?.project?.device_set?.find((device) => device.serial === inspectionContext.state.mobile.selected_qube?.serial);
    return device?.qubedevice as QubeDeviceType;
  }, [inspectionContext.state.mobile.selected_qube, inspectionContext.run?.project?.device_set]);

  const index = useMemo(() => {
    return inspectionContext.state.mobile.selected_cluster_markers.findIndex((marker) => marker.data.qube.serial === qube.serial);
  }, [inspectionContext.state.mobile.selected_cluster_markers, qube]);


  /**
   *
   */
  const createMap = async (device: QubeDeviceType) => {
    const elementId = `#QUBE_MODAL_MAP`;
    const element = document.querySelector(elementId) as HTMLElement;
    const loader = new Loader(GOOGLE_MAPS_LOADER_PARAMETERS);

    await loader.load();
    
    const google = window.google;
    const googleMapOptions = {
      ...DEFAULT_GOOGLE_MAP_OPTIONS,
      center: { lat: device.position.coordinates[1], lng: device.position.coordinates[0] },
    };

    const map = new google.maps.Map(element, googleMapOptions);
    setMap(map);

    createMarker(device, auth.token, map, setMarker);
    return map;
  };

  /**
   *
   */
  const changeMapPosition = (marker, map, device: QubeDeviceType) => {
    marker.position = { lat: device.position.coordinates[1], lng: device.position.coordinates[0] };
    marker.getPosition = () => ({ lat: () => device.position?.coordinates[1], lng: () => device.position?.coordinates[0] });
    map.setCenter({ lat: device.position.coordinates[1], lng: device.position.coordinates[0] });
  };

  useEffect(() => {
    const initialQube = inspectionContext.state.mobile.selected_qube as QubeDeviceType; 
    createMap(initialQube);
  }, []);

  useEffect(() => {
    if (!qube?.position) return;
    if (marker && map) {
      changeMapPosition(marker, map, qube);
      return;
    }
  }, [marker, qube]);

  /**
   * 
   */
  const handlePrevClick = () => {
    if (index === 0) return;
    inspectionContext.dispatch({
      type: 'SET_MOBILE_SELECTED_QUBE',
      data: inspectionContext.state.mobile.selected_cluster_markers[index - 1].data.qube,
    });
  };

  /**
   * 
   */
  const handleNextClick = () => {
    if (index === clusterLength - 1) return;
    inspectionContext.dispatch({
      type: 'SET_MOBILE_SELECTED_QUBE',
      data: inspectionContext.state.mobile.selected_cluster_markers[index + 1].data.qube,
    });
  };

  /**
   * 
   */
  const handleOnClose = () => {
    if (inspectionContext.state.mobile.qubes_modal) {
      inspectionContext.dispatch({
        type: 'MOBILE_QUBES_MODAL',
        data: true,
      });
    }
    
    inspectionContext.dispatch({
      type: 'SET_MOBILE_SELECTED_QUBE',
      data: undefined,
    });
  };

  if (!qube) return null;

  return (
    <QubeModalWrapper id="QUBE_CLUSTER_MODAL">
      <QubeCard 
        qube={qube}
        onClose={handleOnClose}
        auth={auth}
        isObserver={inspectionContext.state.permission_type === 'observer'}
      />
      {
        clusterLength && index !== -1 ? (
          <QubeModalFooter>
            <QubeModalPagination>{index + 1} of {clusterLength}</QubeModalPagination>
            <div>
              <button onClick={handlePrevClick} disabled={index === 0}>
                <span className="material-icons-outlined">chevron_left</span>
              </button>
              <button onClick={handleNextClick} disabled={index + 1 === clusterLength}>
                <span className="material-icons-outlined">chevron_right</span>
              </button>
            </div>
          </QubeModalFooter>
        ) : null
      }
      
      <div id="QUBE_MODAL_MAP" />
    </QubeModalWrapper>
  );
};

export default QubeModal;
