import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationStore from 'app/modules/notification/notification.context';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { InvitationType } from './shareable-invitation.page.d';
import { accept, getInvitation } from './shareable-invitation.controller';
import Button from 'app/components/button/button';
import Avatar from 'app/components/avatar/avatar';
import {
  Actions,
  Content,
  Figure,
  InvitationWrapper,
  InvitationOwner,
  InvitationTitle,
  Message,
  Permission,
  PermissionList,
  PermissionText,
  ProjectPermissions,
  ProjectList,
  ProjectListItem,
} from './shareable-invitation.page.style';
import { roles } from './shareable-invitation.models';

const renderPermissions = (permissions: string[]) =>
  permissions.map((permission) => (
    <Permission key={permission}>{permission}</Permission>
  ));

const ShareableInvitation = () => {
  const history = useHistory();

  // contexts
  const notificationContext = useContext(NotificationStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType | undefined;

  const { token } = useParams<{ token: string }>();
  const [invitation, setInvitation] = useState<InvitationType>();
  const [expired, setExpired] = useState<boolean>(false);
  const isAlreadyParticipant = useMemo(() => {
    if (auth && invitation) {
      return invitation.project.participant_set.findIndex(({ user }) => auth.user.id === user?.id) > -1;
    }
    return false;
  }, [auth, invitation]);
  
  useEffect(() => {
    getInvitation(token, setInvitation, setExpired);
  }, []);

  useEffect(() => {
    if (invitation && auth && auth.user.is_guest) {
      window.localStorage.removeItem('CDI@auth-email');
      window.localStorage.removeItem('CDI@auth-token');
      accountContext.dispatch({ type: 'LOGOUT' });
    } 
  }, [invitation, auth]);

  const fullname = `${invitation?.generated_by.first_name} ${invitation?.generated_by.last_name}`;

  if (!invitation) {
    return null;
  }

  const inviteText = 'Invited you to collaborate at:';
  const inviteTextAlreadyParticipant = 'Invited you to collaborate in a project you are already in:';

  return (
    <Content>               
      <InvitationWrapper>
        <Figure>
          <Avatar
            src={
              typeof invitation?.generated_by.avatar === 'string'
                ? invitation?.generated_by.avatar
                : ''
            }
            email={`${invitation?.generated_by.email}`}
            firstName={invitation?.generated_by.first_name}
            lastName={invitation?.generated_by.last_name}
            size={90}
          />
        </Figure>

        <InvitationTitle>
          <InvitationOwner>{fullname}</InvitationOwner>
          &nbsp;{isAlreadyParticipant ? inviteTextAlreadyParticipant : inviteText}
        </InvitationTitle>

        <ProjectList>
          <ProjectListItem key={invitation?.project.id}>
            {invitation?.project.name}
          </ProjectListItem>
        </ProjectList>

        {(auth || accountContext.state.register_data) && !expired && !isAlreadyParticipant ? (
          <Actions>
            <Button
              customStyle={`
                  width: auto;
                  padding: 8px 16px;
                `}
              type="button"
              handleClick={() =>
                accept(
                  token,
                  auth?.user?.id,
                  accountContext.state.register_data,
                  accountContext.dispatch,
                  notificationContext.dispatch,
                  history
                )
              }
              value="Accept invitation"
            />
            <Button
              customStyle={`
                  width: auto;
                  padding: 8px 16px;

                  background-color: rgba(240, 242, 243, 0.5);
                  color: #161e2e;
                `}
              type="button"
              handleClick={() => {
                if (auth) {
                  history.push(`/projects`);
                } else {
                  history.push(`/`);
                }
              }}
              value="Decline"
            />
          </Actions>
        ) : null}

        {!auth && !accountContext.state.register_data && !expired && !isAlreadyParticipant ? (
          <>
            <Message>To accept the invitation:</Message>
            <Actions>
              <Button
                customStyle={`
                    width: auto;
                    padding: 8px 16px;
                  `}
                type="button"
                handleClick={() =>
                  history.push(`/?redirect_to=shareable-invitation&t=${token}`)
                }
                value="Log in"
              />
              <Button
                customStyle={`
                    width: auto;
                    padding: 8px 16px;

                    background-color: rgba(240, 242, 243, 0.5);
                    color: #161e2e;
                  `}
                type="button"
                handleClick={() =>
                  history.push(
                    `/register?t=${token}&redirect_to=shareable-invitation`
                  )
                }
                value="Register account"
              />
            </Actions>
          </>
        ) : null}

        {expired ? (
          <>
            <Message>Link expired!</Message>
            <Button
              customStyle={`
                      width: auto;
                      padding: 8px 16px;
                      margin-top: 8px;
                      margin-bottom: 32px;

                      background-color: rgba(240, 242, 243, 0.5);
                      color: #161e2e;
                    `}
              type="button"
              handleClick={() => {
                history.push('/projects');
              }}
              value="Return to home"
            />
          </>
        ) : null}

        {isAlreadyParticipant && !expired ? (
          <>
            <Message>You already are participant this project!</Message>
            <Button
              customStyle={`
                    width: auto;
                    padding: 8px 16px;
                    margin-top: 8px;
                    margin-bottom: 32px;

                    background-color: rgba(240, 242, 243, 0.5);
                    color: #161e2e;
                  `}
              type="button"
              handleClick={() => {
                history.push(`/projects/${invitation?.project.id}`);
              }}
              value="Go to project page"
            />
          </>
        ) : null}

        <ProjectPermissions>
          <PermissionText>
            <strong>{invitation ? roles[`${invitation?.role}`].label : ''}</strong> will be able to:
          </PermissionText>
          <PermissionList>
            {renderPermissions(invitation ? roles[`${invitation?.role}`].permissions : [])}
          </PermissionList>
        </ProjectPermissions>
      </InvitationWrapper>
    </Content>
  );
};

export default ShareableInvitation;
