import styled from 'styled-components';

export const ChartName = styled.label`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  font-size: 0.9rem;
  position: relative;
  top: -18px;
`;

export const InfoData = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns:  140px 130px 1fr;
  gap: 4px;
`;

export const ZoomLabel = styled.label`
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  font-size: 0.7rem;
`;

export const ResetZoom = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 0.7rem;

  height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #fff;

  border: 1px solid ${({ theme }) => theme.colors.neutra_300};
  border-radius: 6px;

  cursor: pointer;

  transition-duration: 400ms;
`;

export const FixedRangeWrapper = styled.div`
  font-size: 0.7rem;
  gap: 4px;
  line-height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  >label {
    display: block;
  }
`;

export const Checkbox = styled.div`
  height: 12px;
  width: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutra_300};
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MenuButton = styled.button<{ $hide: boolean }>`
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  background-color: #5850ec;
  color: white;

  cursor: pointer;
  position: relative;
  font-weight: 500;

  ${({ $hide }) => ($hide ? 'display: none;' : '')}
`;

export const MenuListItem = styled.div`
  padding: 8px;

  display: grid;
  grid-template-columns: auto;
  justify-items: start;

  gap: 6px;

  font-size: 13px;
  font-weight: 500;

  color: #6b7280;

  :hover {
    background-color: #f1f1f1;
  }
`;

export const MenuListWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  bottom: 40px;
  right: 0;

  display: grid;


  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;

  overflow: hidden;
  z-index: 3;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;
