import { useContext, useRef, useState, useEffect } from 'react';
import InspectionStore from 'app/modules/inspection/inspection.context';
import OverlayHeader from './components/overlay-run-header/overlay-run-header';
import TrackingPointOverlay from './components/tracking-point-overlay/tracking-point-overlay';
import SatelitePassagesOverlay from './components/satelite-passages-overlay/satelite-passages-overlay';
import TrackingSheet from './components/tracking-sheet/tracking-sheet';
import ProjectCode from './components/project-code/project-code';
import { DesktopRunContainer } from './desktop.style';
import QubeTriggersOverlay from './components/qube-triggers-overlay/qube-triggers-overlay';
import TrackingPointGallery from './components/tracking-point-gallery/tracking-point-gallery';
import Map from 'app/components/common/map/map';
import DesktopObserverView from './components/desktop-observer-view/desktop-observer-view';
import { handleDrag } from './components/tracking-sheet/tracking-sheet.controller';

/**
 *
 * @returns
 */
const Desktop = () => {
  const container = useRef<HTMLDivElement>(null);
  const inspectionContext = useContext(InspectionStore);
  const [bounds, setBounds] = useState<google.maps.LatLng[]>([]);
  const viewMode = inspectionContext.state.view_mode;
  const guestsEnabled = inspectionContext.run?.project?.guests_enabled;

  useEffect(() => {
    if (inspectionContext.state.permission_type === 'observer') {
      inspectionContext.dispatch({ type: 'SET_VIEW_MODE', data: 'observer' });
    } else if (inspectionContext.state.permission_type === 'editor') {
      inspectionContext.dispatch({ type: 'SET_VIEW_MODE', data: 'editor' });
    }
  }, [inspectionContext.state.permission_type]);

  useEffect(() => {
    if (inspectionContext.state.view_mode === 'observer' && container.current) {
      container.current.style.gridTemplateRows = 'auto 1fr';
    } else if (container.current) {
      handleDrag(0, container.current, inspectionContext.state.grid, true);
    }
  }, [inspectionContext.state.view_mode]);

  return (
    <DesktopRunContainer ref={container} $isobserver={inspectionContext.state.view_mode === 'observer'}>
      <OverlayHeader />
      <Map
        bounds={bounds}
        zoom={16}
        modules={['run']}
        streetView
        view_mode={inspectionContext.state.view_mode}
        onMapLoaded={(map) => inspectionContext.dispatch({ type: 'SET_MAP', data: map })}
        controllers={[
          'labels',
          'follow',
          'users',
          'measure',
          'view',
          'zoom',
          'map-type',
        ]}
      />
      
      {viewMode === 'editor' ? <TrackingSheet container={container} setBounds={setBounds} /> : null}
      {viewMode === 'observer' ? <DesktopObserverView /> : null}

      {inspectionContext.state.selected_point ? <TrackingPointOverlay /> : null}
      {inspectionContext.state.selected_media !== undefined ? <TrackingPointGallery /> : null}
      {inspectionContext.state.selected_point_triggers ? <SatelitePassagesOverlay /> : null}
      {inspectionContext.state.selected_trigger_passage ? <QubeTriggersOverlay /> : null}

      {guestsEnabled ? <ProjectCode /> : null}
    </DesktopRunContainer>
  )
};

export default Desktop;
