import FingerprintJS from '@fingerprintjs/fingerprintjs';
import moment from 'moment-timezone';
import QueryString from 'qs';
import { verifySurveyAdmin } from "app/modules/account/private-wrapper.controller";
import { projectCodeLoginRequest } from "app/services/auth.service";
import { loadAllProjects } from "app/services/projects.service";
import { AccountDispatchType } from 'app/modules/account/account.context.d';
import { RouteComponentProps } from 'react-router-dom';

interface SubmitProjectCodeLogin {
  projectCode: string;
  dispatch(data: AccountDispatchType): void;
  setError(error: any): void;
  history: RouteComponentProps['history'];
}


/**
 * 
 * @param e 
 * @param param1 
 * @returns 
 */
export const submit = async (
  e: React.FormEvent<HTMLFormElement>,
  { projectCode, setError, dispatch }: SubmitProjectCodeLogin
) => {
  e.preventDefault();
  let loginResponse: any;
  const fpPromise = await FingerprintJS.load();
  const response = await fpPromise.get();
  const fingerprint = response.visitorId;
  const timezone = moment.tz.guess();
  const unexpectedError = 'Sorry unexpected error, try again in some minutes';

  try {
    loginResponse = await projectCodeLoginRequest(projectCode, fingerprint, timezone);
  } catch (err: any) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      setError(err.response.data || unexpectedError);
      return;
    } else {
      setError(unexpectedError);
    }
  }

  await verifySurveyAdmin(
    loginResponse?.data?.user,
    loginResponse?.data?.token,
    dispatch
  );

  window.localStorage.setItem('CDI@auth-token', loginResponse?.data?.token);
  window.localStorage.setItem(
    'CDI@auth-email',
    loginResponse?.data?.user?.email
  );

  await loadAllProjects(loginResponse?.data.token);
  
  dispatch({
    type: 'LOGIN',
    data: loginResponse?.data,
  });

  const url = new URL(window.location.href);
  url.searchParams.delete('modal');
  QueryString.stringify(url.searchParams, { addQueryPrefix: true });

  window.location.href = '/';
};
