import styled from 'styled-components';

export const ProjectCodeModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
  padding: 16px;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 30;
`;

export const ProjectCodeModalContainer = styled.div`
  display: grid;

  width: 100%;
  max-width: 420px;

  padding: 24px;

  background-color: #ffffff;
  border-radius: 6px;

  > header {
    margin-bottom: 24px;
  }

  > form {
    display: grid;
    gap: 16px;

    max-width: 280px;
    margin-left: auto;
    margin-right: auto;

    > p {
      color: #ff0000;
    }
  }
`;
