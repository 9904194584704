import { MaterialIcon } from 'material-icons';
import { Button } from './icon-button.style';

interface Props {
  onClick: () => void;
  styleType?: 'primary' | 'secondary' | 'transparent';
  disabled?: boolean; 
  materialType?: 'material-icons-outlined' | 'material-symbols-rounded' | 'material-symbols-outlined' | 'material-icons';
  icon?: MaterialIcon;
}

/**
 * 
 * @param props 
 */
const IconButton = ({
  onClick,
  styleType = 'primary',
  materialType = 'material-icons-outlined',
  disabled = false,
  icon = undefined,
}: Props) => {
  return (
    <Button
      type="button"
      disabled={disabled}
      onClick={onClick}
      $style={styleType}
    >
      {icon ? <span className={materialType}>{icon}</span> : null}
    </Button>
  );
};

export default IconButton;