import { TriggerType } from 'app/modules/detection/detection.interfaces';
import { DownloadWrapper, FixedRangeWrapper, InfoData, MenuButton, MenuListItem, MenuListWrapper, ResetZoom, Wrapper, ZoomLabel } from './charts.style';
import MagneticChart from './components/magnetic-chart/magnetic-chart';
import GeophoneChart from './components/magnetic-chart/geophone-chart';
import { useContext, useEffect, useState } from 'react';
import SettingsStore from 'app/modules/settings/settings.context';
import moment from 'moment-timezone';
import { getMediaUrl } from '../../devices/desktop/components/qube-triggers-overlay/qube-triggers-overlay.controller';
import PlayerController from './components/magnetic-chart/player-controller';

interface Props {
  trigger: TriggerType | undefined;
}

export interface MousePosition {
  x: Date;
}

export interface ZoomDomain {
  x: [Date, Date];
  y: [number, number];
}



/**
 *
 * @returns
 */
const Charts = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const [zoomDomain, setZoomDomain] = useState<ZoomDomain>();
  const [mousePosition, setMousePosition] = useState<MousePosition>();
  const [audioPosition, setAudioPosition] = useState<number>(0);
  const [audioBar, setAudioBar] = useState<Date | undefined>();
  const [rangeY, setRangeY] = useState<[number, number]>();
  const [rangeX, setRangeX] = useState<[Date, Date]>();
  const [player, setPlayer] = useState<PlayerController>();
  const [zoomPercentage, setZoomPercentage] = useState<string>('0');
  const [lastTrigger, setLastTrigger] = useState<number>();
  const [isFixedScale, setIsFixedScale] = useState<boolean>(true);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const delta = rangeY ? (rangeY[1] - rangeY[0]) / 15 : 0;

  useEffect(() => {
    resetZoomChart();
  }, [rangeX]);

  useEffect(() => {
    if (zoomDomain && rangeX) {
      const initDate = rangeX[0].getTime();
      const endDate = rangeX[1].getTime();
      const zoomInitDate = zoomDomain.x[0].getTime();
      const zoomEndDate = zoomDomain.x[1].getTime();

      const fullRangeLength = endDate - initDate;
      const zoomedRangeLength = zoomEndDate - zoomInitDate;

      const zoomPercentage = ((zoomedRangeLength / fullRangeLength) -1) * -100;
      setZoomPercentage(zoomPercentage.toFixed(2));
    }

  }, [zoomDomain, rangeX]);

  useEffect(() => {
      if (!lastTrigger || !props.trigger?.id) {
        setLastTrigger(props.trigger?.id || undefined);
      }
      if (props.trigger?.id !== lastTrigger) {
        setRangeX([new Date(0), new Date(100000)]);
        setRangeY([0, 0]);
        setMousePosition(undefined);
        setLastTrigger(props.trigger?.id || undefined);
      };
  }, [props.trigger]);

  const resetZoomChart = () => {
    if (rangeX && rangeY) {
      const timezone = settingsContext.state.timezone?.id;

      const initTime = rangeX[0];
      const endTime = rangeX[1];

      const tzInitDate = moment.tz(initTime, timezone || 'UTC');
      const tzEndDate = moment.tz(endTime, timezone || 'UTC');

      const initDate = tzInitDate.toDate();
      const endDate = tzEndDate.toDate();

      setZoomDomain({ x: [initDate, endDate], y: [rangeY[0], rangeY[1] + delta] });
    }
  };

  if (!props.trigger?.medias?.acx && !props.trigger?.medias?.dcx) {
    return (
      <Wrapper>
        <ZoomLabel>
          This passage information has no additional data available at this time.
        </ZoomLabel>
      </Wrapper>
    );
  }

  return (
    <Wrapper onMouseLeave={() => setMousePosition(undefined)}>
      <MagneticChart
        trigger={props.trigger}
        resetZoomChart={resetZoomChart}
        setZoomDomain={setZoomDomain}
        zoomDomain={zoomDomain}
        setMousePosition={setMousePosition}
        mousePosition={mousePosition}
        player={player}
        audioBar={audioBar}
        setRangeY={(y) => {
          if (!rangeY || y[0] === 0 || y[1] === 0) {
            return setRangeY(y);
          }
          const ltY = y[0] < rangeY[0] ? y[0] : rangeY[0];
          const gtY = y[1] > rangeY[1] ? y[1] : rangeY[1];
          setRangeY([ltY, gtY]);
        }}
        setRangeX={(x) => {
            return setRangeX(x);
        }}
        rangeY={[0, 200]}
        rangeX={rangeX}
        delta={delta}
        fixed_scale={isFixedScale}
        type="ac"
        title="Transmitter Data"
        height={150}
      />
      <MagneticChart
        trigger={props.trigger}
        resetZoomChart={resetZoomChart}
        setZoomDomain={setZoomDomain}
        zoomDomain={zoomDomain}
        setMousePosition={setMousePosition}
        mousePosition={mousePosition}
        player={player}
        audioBar={audioBar}
        setRangeY={(y) => {
          if (!rangeY || y[0] === 0 || y[1] === 0) {
            return setRangeY(y);
          }
          const ltY = y[0] < rangeY[0] ? y[0] : rangeY[0];
          const gtY = y[1] > rangeY[1] ? y[1] : rangeY[1];
          setRangeY([ltY, gtY]);
        }}
        setRangeX={(x) => {
            return setRangeX(x);
        }}
        rangeY={[-100, 100]}
        rangeX={rangeX}
        fixed_scale={isFixedScale}
        delta={delta}
        type="dc"
        title="Magnetic Data"
        height={150}
      />
      <GeophoneChart
        trigger={props.trigger}
        resetZoomChart={resetZoomChart}
        setPlayer={setPlayer}
        setZoomDomain={setZoomDomain}
        zoomDomain={zoomDomain}
        setMousePosition={setMousePosition}
        mousePosition={mousePosition}
        setAudioPosition={setAudioPosition}
        audioPosition={audioPosition}
        setAudioBar={setAudioBar}
        audioBar={audioBar}
        setRangeY={(y) => {
          if (!rangeY || y[0] === 0 || y[1] === 0) {
            return setRangeY(y);
          }
          const ltY = y[0] < rangeY[0] ? y[0] : rangeY[0];
          const gtY = y[1] > rangeY[1] ? y[1] : rangeY[1];
          setRangeY([ltY, gtY]);
        }}
        setRangeX={(x) => {
            return setRangeX(x);
        }}
        rangeY={[-100, 100]}
        rangeX={rangeX}
        fixed_scale={isFixedScale}
        delta={delta}
        title="Geophone"
        height={140}
      />
      <InfoData>
        <FixedRangeWrapper>
          <label>{`X scale: ${zoomPercentage}%`}</label>
          <ResetZoom onClick={() => resetZoomChart()}>{`Reset`}</ResetZoom>
        </FixedRangeWrapper>
        <FixedRangeWrapper>
          <label>{`Y scale: ${isFixedScale ? 'Fixed' : 'Fit'}`}</label>
           <ResetZoom onClick={() => setIsFixedScale(!isFixedScale)}>{`Toggle`}</ResetZoom>
        </FixedRangeWrapper>
        <DownloadWrapper>
          <MenuButton
            $hide={false}
            onClick={() => setShowMenu(!showMenu)}
          >
            Download
            <MenuListWrapper hide={!showMenu}>
              <MenuListItem
                onClick={() => {
                  window.open(getMediaUrl(props.trigger, 'acx'), '_blank');
                  setShowMenu(!showMenu);
                }}
              >
                acx
              </MenuListItem>
              <MenuListItem
                onClick={() => {
                  window.open(getMediaUrl(props.trigger, 'acy'), '_blank');
                  setShowMenu(!showMenu);
                }}
              >
                acy
              </MenuListItem>
              <MenuListItem
                onClick={() => {
                  window.open(getMediaUrl(props.trigger, 'dcx'), '_blank');
                  setShowMenu(!showMenu);
                }}
              >
                dcx
              </MenuListItem>
              <MenuListItem
                onClick={() => {
                  window.open(getMediaUrl(props.trigger, 'dcy'), '_blank');
                  setShowMenu(!showMenu);
                }}
              >
                dcy
              </MenuListItem>
              <MenuListItem
                onClick={() => {
                  window.open(getMediaUrl(props.trigger, 'gphone'), '_blank');
                  setShowMenu(!showMenu);
                }}
              >
                gphone
              </MenuListItem>
              <MenuListItem
                onClick={() => {
                  window.open(getMediaUrl(props.trigger, 'metadata'), '_blank');
                  setShowMenu(!showMenu);
                }}
              >
                metadata
              </MenuListItem>
            </MenuListWrapper>
          </MenuButton>
        </DownloadWrapper>
      </InfoData>
    </Wrapper>
  );
};

export default Charts;
