import { useContext, useEffect, useMemo, useState } from 'react';
import IconButton from 'stories/base/buttons/icon-button/icon-button';
import Typograph from 'stories/type/typograph/typograph';
import {
  OverlayContainer,
  OverlayContent,
  OverlayHeader,
  OverlayCharts,
  OverlayPassagesContent,
  ChartTypeWrapper,
  ChartTypeGroup,
  ChartTypeItem,
  FixedRangeWrapper,
  ResetZoom,
  ActionsBar,
} from './trigger-detail-overlay.style';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { TriggerType } from 'app/modules/detection/detection.interfaces';
import SatellitePassages from 'app/modules/inspection/run/components/satellite-passages/satellite-passages';
import MagneticChart from 'app/modules/inspection/run/components/charts/components/magnetic-chart/magnetic-chart';
import { ZoomDomain } from 'app/modules/inspection/run/components/charts/charts';
import { formatTimezoneDate } from '@/lib/formatter';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';
import moment from 'moment-timezone';

/**
 *
 * @param props
 */
const TriggerDetailOverlay = () => {
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const agm = inspectionContext.state.mobile.selected_point_to_trigger as TrackingPointCascadeType;
  const trigger = inspectionContext.state.mobile.selected_trigger as TriggerType;

  // Range for whole dataset
  const [rangeY, setRangeY] = useState<[number, number]>([0, 0]);
  const [rangeX, setRangeX] = useState<[Date, Date]>([new Date(0), new Date(0)]);
  const [isFixedScale, setIsFixedScale] = useState<boolean>(false);
  const delta = (rangeY[1] - rangeY[0]) / 15;

  const [zoomDomain, setZoomDomain] = useState<ZoomDomain>();
  const [type, setType] = useState<{label: string, type: 'ac' | 'dc'}>({
    type: "ac",
    label: 'Transmitter Data',
  });

  const width = useMemo(() => window.innerWidth, []);
  const height = useMemo(() => window.innerHeight, []);

  const tstamp = useMemo(() => {
    const timezone = settingsContext.state.timezone?.id || 'utc';
    return formatTimezoneDate({
      date: trigger.tstamp,
      timezone,
      format: 'H:mm:ss A',
    })
  }, [trigger.tstamp]);
  const zoomPercentage = useMemo(() => {
    if (zoomDomain && rangeX) {
      const initDate = rangeX[0].getTime();
      const endDate = rangeX[1].getTime();
      const zoomInitDate = zoomDomain.x[0].getTime();
      const zoomEndDate = zoomDomain.x[1].getTime();

      const fullRangeLength = endDate - initDate;
      const zoomedRangeLength = zoomEndDate - zoomInitDate;

      const zoomPercentage = ((zoomedRangeLength / fullRangeLength) -1) * -100;
      return zoomPercentage.toFixed(2);
    } else {
      return 0;
    }
  }, [zoomDomain, rangeX]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  /**
   *
   */
  const handleClose = () => {
    inspectionContext.dispatch({
      type: 'SET_MOBILE_SELECTED_TRIGGER',
      data: undefined,
    });

    inspectionContext.dispatch({
      type: 'SET_MOBILE_SELECTED_POINT_TO_TRIGGER',
      data: undefined,
    });
  };
  const resetZoomChart = () => {
    if (rangeX && rangeY) {
      const timezone = settingsContext.state.timezone?.id;

      const initTime = rangeX[0];
      const endTime = rangeX[1];

      const tzInitDate = moment.tz(initTime, timezone || 'UTC');
      const tzEndDate = moment.tz(endTime, timezone || 'UTC');

      const initDate = tzInitDate.toDate();
      const endDate = tzEndDate.toDate();

      setZoomDomain({ x: [initDate, endDate], y: [rangeY[0], rangeY[1] + delta] });
    }
  };

  const rRangeY: [number, number] = type.type === 'ac' ? [0, 200] : [-100, 100]

  return (
    <OverlayContainer>
      <OverlayHeader>
        <Typograph type="headline6" text={agm.name} />
        <div>
          <Typograph type="caption" text={`${agm.trigger_set?.length} triggers total`} />
          <IconButton icon="close" onClick={handleClose} styleType="transparent" />
        </div>
      </OverlayHeader>
      <OverlayContent>
        <OverlayCharts>
          <Typograph type="headline6" text={tstamp} />
          <div>
            <MagneticChart
              width={width * 1.5}
              height={height * 0.5}
              resetZoomChart={() => {}}
              trigger={trigger}
              setZoomDomain={setZoomDomain}
              zoomDomain={zoomDomain}
              setRangeY={(y) => {
                if (!rangeY || y[0] === 0 || y[1] === 0) {
                  return setRangeY(y);
                }
                const ltY = y[0] < rangeY[0] ? y[0] : rangeY[0];
                const gtY = y[1] > rangeY[1] ? y[1] : rangeY[1];
                setRangeY([ltY, gtY]);
              }}
              setRangeX={(x) => {
                return setRangeX(x);
              }}
              rangeY={rRangeY}
              rangeX={rangeX}
              delta={delta}
              mousePosition={undefined}
              fixed_scale={isFixedScale}
              setMousePosition={() => undefined}
              type={type?.type || 'ac'}
              title={type?.label || ''}
            />
          </div>
          <ChartTypeWrapper>
            <ChartTypeGroup>
              <ChartTypeItem
                isSelected={type?.type == 'ac'}
                onClick={() => setType({
                  type: "ac",
                  label: 'Transmitter Data',
                })}
              >Transmitter Data</ChartTypeItem>
              <ChartTypeItem
                isSelected={type?.type == 'dc'}
                onClick={() => setType({
                  type: 'dc',
                  label: 'MAG',
                })}
              >MAG</ChartTypeItem>
            </ChartTypeGroup>
          </ChartTypeWrapper>
          <ActionsBar>
            <FixedRangeWrapper>
              <label>{`Y scale: ${isFixedScale ? 'Fixed' : 'Fit'}`}</label>
              <ResetZoom onClick={() => setIsFixedScale(!isFixedScale)}>{`Toggle`}</ResetZoom>
            </FixedRangeWrapper>
            <FixedRangeWrapper>
              <label>{`X scale: ${zoomPercentage}%`}</label>
              <ResetZoom onClick={() => resetZoomChart()}>{`Reset`}</ResetZoom>
            </FixedRangeWrapper>
          </ActionsBar>
        </OverlayCharts>
        <OverlayPassagesContent>
          <SatellitePassages
            agm={agm}
            onClose={handleClose}
            onTriggerClick={(trigger) => {
              inspectionContext.dispatch({
                type: 'SET_MOBILE_SELECTED_TRIGGER',
                data: trigger,
              });
            }}
            authToken={auth.token}
            isObserver={inspectionContext.state.permission_type === 'observer'}
            selected={trigger}
          />
        </OverlayPassagesContent>
      </OverlayContent>
    </OverlayContainer>
  );
};

export default TriggerDetailOverlay;
