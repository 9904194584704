import styled from 'styled-components';

export const CodeInputContainer = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.length}, 1fr);
  gap: 8px;

  > input {
    width: 40px;
    height: 48px;
    text-align: center;

    border-radius: 6px;

    border: 1px solid #ADB0C1;

    font-family: Inter;
    font-size: 30px;
    font-weight: 400;
    color: #6D7186;
  }
`;