import { useState, useContext, useEffect, ChangeEvent } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
// context
import AccountStore from '../account.context';
// interfaces
import { RegisterData } from '../account.context.d';
import PublicWrapper from '../public-wrapper';
import {
  RegisterContainer,
  RegisterHeader,
  RegisterMain,
  RegisterForm,
  RegisterError,
  RegisterFooter,
  RegistrationTerms,
} from './register.page.style';
import {
  acceptInvitation,
  acceptSharableInvitation,
  getInvitation,
  verifyAuth,
} from './register.controller';
import NotificationStore from 'app/modules/notification/notification.context';
import Toast from 'app/components/toast/toast';
import Typograph from 'stories/type/typograph/typograph';
import Input from 'stories/base/forms/input/input';
import PhoneInput from 'stories/base/forms/phone-input/phone-input';
import PasswordInput from 'stories/base/forms/password-input/password-input';
import FilledButton from 'stories/base/buttons/filled-button/filled-button';
import GtLogo from '../login/components/gt-logo/gt-logo';

interface ErrorType {
  email?: string;
  firstName?: string;
  phoneNumber?: string;
  lastName?: string;
  timezone?: string;
  password?: string;
  confirmationPassword?: string;
  phone?: string;
  general?: string;
}

const queryStringToObject = (queryString: string) => {
  const obj: any = {};
  if (queryString) {
    queryString
      .slice(1)
      .split('&')
      .map((item: string) => {
        const [k, v] = item.split('=');
        return v ? (obj[k] = v) : null;
      });
  }
  return obj;
};

/**
 * Register component
 * @returns
 */
const Register = () => {
  // router
  const history = useHistory<RouteComponentProps['history']>();
  const { t, redirect_to } = queryStringToObject(history.location.search);
  // context
  const accountContext = useContext(AccountStore);
  const notificationContext = useContext(NotificationStore);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setlastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('US');
  const [countryCallingCode, setCountryCallingCode] = useState<string>('1');
  const [confirmationPassword, setConfirmationPassword] = useState<string>('');
  const [error, setError] = useState<ErrorType>({});
  const [verified, setVerified] = useState<boolean>(false);
  const emailRxp = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneRxp = /^([0-9])*$/;

  useEffect(() => {
    const token: string | null = window.localStorage.getItem('CDI@auth-token');
    verifyAuth(token, history, setVerified);

    if (redirect_to === 'invitation') getInvitation(t, setEmail, history);
  }, []);

  if (!verified) return null;

  const disabled = !firstName || !lastName || !password || !email || !emailRxp.test(email) || !phoneRxp.test(phoneNumber) || confirmationPassword !== password;

  /**
   * function to handle form submission
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const data: RegisterData = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      country_code: countryCallingCode,
      password,
      timezone: moment.tz.guess(),
    };

    const redirect = {
      invitation: () => {
        accountContext.dispatch({
          type: 'SET_REGISTER_DATA',
          data,
        });
        acceptInvitation(t, data, accountContext.dispatch, history);
      },
      'shareable-invitation': () =>
        acceptSharableInvitation(
          t,
          data,
          accountContext.dispatch,
          notificationContext,
          history,
          setError
        ),
    };

    redirect[
      redirect_to as 'invitation' | 'shareable-invitation'
    ]();
  };

  return (
    <PublicWrapper>
      <RegisterContainer>
        <RegisterHeader>
          <GtLogo />
        </RegisterHeader>

        <RegisterMain>
          <RegisterForm onSubmit={handleSubmit} autoComplete="off">
            <Typograph type="headline6">
              <strong>Join CDI Software Workspace</strong>
            </Typograph>

            {
              error?.general && (
                <RegisterError data-testid="register-email-error">
                  <Typograph type="body2" data-testid="register-email-error">
                    {error.general}
                  </Typograph>
                </RegisterError>
              )
            }

            <fieldset>
              <Input
                label="Email"
                id="email"
                disabled={redirect_to === 'invitation'}
                error={error.email}
                errorTestId="register-email-error"
                placeholder="Enter your email"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
              />

              <Input
                label="First Name"
                id="first-name"
                error={error.firstName}
                errorTestId="register-fname-error"
                value={firstName}
                onChange={({ target }) => setFirstName(target.value)}
              />

              <Input
                label="Last Name"
                id="last-name"
                error={error.lastName}
                errorTestId="register-lname-error"
                value={lastName}
                onChange={({ target }) => setlastName(target.value)}
              />

              <PhoneInput
                label="Phone Number"
                error={error.phone}
                phone={phoneNumber}
                country={countryCode}
                onCountryChange={(code, country) => {
                  setCountryCode(country);
                  setCountryCallingCode(code);
                }}
                onPhoneChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (phoneRxp.test(e.target.value)) {
                    setPhoneNumber(e.target.value);

                    const newErrors = { ...error };
                    delete newErrors.phone;
                    setError(newErrors);
                  }
                }}
              />

              <PasswordInput
                label="Password"
                id="password"
                error={error.password}
                visibilityTestId="register-password-visibility"
                value={password}
                onChange={({ target }: any) => setPassword(target.value)}
              />

              <PasswordInput
                label="Confirm Password"
                id="confirm-password"
                error={error.confirmationPassword}
                visibilityTestId="register-confirm-password-visibility"
                value={confirmationPassword}
                onChange={({ target }: any) => setConfirmationPassword(target.value)}
              />
            </fieldset>

            <FilledButton disabled={disabled} text={redirect_to === 'invitation' ? 'Next' : 'Create Account'} type="submit" />

            <RegistrationTerms>
              By clicking the &quot;Create Account&quot; button, you are creating
              a GlobalTrack account, and you agree to GlobalTrack&apos;s&nbsp;
              <a>Terms of Use</a> and&nbsp;
              <a>Privacy Policy</a>.
            </RegistrationTerms>
          </RegisterForm>
        </RegisterMain>

        <RegisterFooter>
          <Typograph type="body2">
            Having a trouble to accesss? 
          </Typograph>
          <Typograph type="body2">
            Email us at <strong>globaltrack@pigging.com</strong>
          </Typograph>

          {/* <div>
            <TextButton text="Privacy & Terms" onClick={handlePrivacyAndTems}/>
            <TextButton text="Cookie Policy" onClick={handleCookiePolicy}/>
          </div> */}
        </RegisterFooter>
      </RegisterContainer>

      <Toast />
    </PublicWrapper>
  );
};

export default Register;