import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InvitationType } from './invitation.page.d';
import { getInvitation, logout } from './invitation.controller';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';

const Invitation = () => {
  const history = useHistory();

  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType | undefined;

  const { token } = useParams<{ token: string }>();
  const [invitation, setInvitation] = useState<InvitationType | null>(null);

  useEffect(() => {
    getInvitation(token, setInvitation);
  }, []);

  useEffect(() => {
    if (invitation && auth && auth.user.is_guest) {
      window.localStorage.removeItem('CDI@auth-email');
      window.localStorage.removeItem('CDI@auth-token');
      accountContext.dispatch({ type: 'LOGOUT' });
    } 
  }, [invitation, auth]);

  useEffect(() => {
    if (accountContext.state.verified && invitation) {
      if (
        !auth &&
        !invitation.registered &&
        !accountContext.state.register_data
      ) {
        history.push(`/register?t=${token}&redirect_to=invitation`);
      }

      if (!auth && invitation?.registered) {
        history.push(`/?redirect_to=invitation&t=${token}`);
      }

      if (auth && auth.user.email !== invitation?.email) {
        logout(accountContext.dispatch, history, token, invitation.registered);
      }

      if (auth?.user.email === invitation.email && invitation.accepted_on) {
        if (invitation.project_set.length === 1) {
          history.push(`/projects/${invitation.project_set[0].project.id}`);
        } else {
          history.push(`/projects`);
        }
      }
    }
  }, [accountContext.state.verified, invitation]);

  return (
    <div />
  );
};

export default Invitation;
