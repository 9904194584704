import { useContext, useMemo, useState } from "react";
import { formatTimezoneDate } from "@/lib/formatter";
import InspectionStore from "app/modules/inspection/inspection.context";
import SettingsStore from "app/modules/settings/settings.context";
import Skeleton from "app/components/skeleton/skeleton";
import ScheduledState from "./components/scheduled-state/scheduled-state";
import FinishedState from "./components/finished-state/finished-state";
import LaunchedState from "./components/launched-state/launched-state";
import ScheduleDropdown from "./components/schedule-dropdown/schedule-dropdown";
import {
  ScheduledOnButtonWrapper,
  ScheduleoDateTimeButton,
} from './scheduled-on-button.style';

/**
 * ScheduledOnButton component
 * @returns
 */
const ScheduledOnButton = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingSContext = useContext(SettingsStore);
  const [open, setOpen] = useState(false);
  const dateTimeText = useMemo(() => {
    if (!inspectionContext.run) return;
    const run = inspectionContext.run;
    const status = run.launched ? 'Launched on' : 'Scheduled on';
    const tstamp = run.launched ? run.launch_time : run.scheduled_launch_at;
    const formattedDateTime = formatTimezoneDate({
      date: tstamp,
      timezone: settingSContext.state.timezone?.id || 'utc',
      format: 'll',
    });

    return `${status} ${formattedDateTime}`;
  }, [
    inspectionContext.run?.scheduled_launch_at,
    inspectionContext.run?.launch_time,
    inspectionContext.run?.is_finished,
    settingSContext.state.timezone?.id
  ]);
  
  return (
    <ScheduledOnButtonWrapper data-testid="scheduled-date-time-group">
      <ScheduleoDateTimeButton
        data-testid="scheduled-date-time-button"
        onClick={() => { 
          if (inspectionContext.state.permission_type === 'editor' && !inspectionContext.run?.launched) {
            setOpen(!open);
          }
        }}
        disabled={inspectionContext.state.permission_type !== 'editor' || !!inspectionContext.run?.launched}
      >
        <Skeleton loading={!dateTimeText} width="100px" height="20px">
          {dateTimeText}
        </Skeleton>
      </ScheduleoDateTimeButton>
      
      {
        open ? (
          <ScheduleDropdown
            open={open}
            onClose={() => { setOpen(false) }}
          /> 
        ) : null
      }

      {!inspectionContext.run?.launched ? <ScheduledState /> : null}
      {inspectionContext.run?.launched && !inspectionContext.run.is_finished ? <LaunchedState /> : null}
      {inspectionContext.run?.launched && inspectionContext.run.is_finished ? <FinishedState /> : null}
    </ScheduledOnButtonWrapper>
  );
};

ScheduledOnButton.defaultProps = {};
export default ScheduledOnButton;
