import { useState } from 'react';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import { AuthType } from 'app/modules/account/account.context.d';
import { setArmed } from "app/components/devices/device-common.controller";
import {
  QubeModalQubeInformations,
  QubeInfoTable,
  QubePropertiesList,
  QubeProperty,
  QubePropertyStatus,
  QubePropertyLabel,
  QubeModalHeader,
  QubeModalSerial,
  QubeModalBattery,
  QubeModalGoBackButton,
  QubeModalCopyButton,
  QubeModalCopied,
  QubeModalStatus,
  QubeCardContainer,
} from './qube-card.style';

/**
 * 
 * @param target 
 */
const copy = (id: string) => {
  const target = document.getElementById(id) as HTMLElement;
  const range = document.createRange();
  const selection = window.getSelection();

  // Clear any existing selections
  selection?.removeAllRanges();

  // Define the range you want to select
  range.setStart(target, 0);
  range.setEnd(target, target.childNodes.length);

  // Add the range to the selection
  selection?.addRange(range);

  navigator.clipboard.writeText(target.textContent || '');
};


/**
 * format qube uptime
 * @returns
 */
const getUptime = (secs: number) : string => {
  if (!secs) return '-';

  const fixZero = (n: number) => (n < 10 ? `0${n.toFixed(0)}` : n.toFixed(0));

  const minutes = Math.floor(secs / 60);
  const secondsUpTo = secs % 60;
  const hours = Math.floor(minutes / 60);
  const minutesUpTo = minutes % 60;

  return `${fixZero(hours)}:${fixZero(minutesUpTo)}:${fixZero(secondsUpTo)}`;
};

interface Props {
  auth: AuthType;
  qube: QubeDeviceType;
  isObserver: boolean;
  onClose: () => void;
}

/**
 * 
 * @param props 
 * @returns 
 */
const QubeCard = (props: Props) => {
  const [copiedCoords, setCopiedCoords] = useState<boolean>(false);
  const [copiedSerial, setCopiedSerial] = useState<boolean>(false);
  
  const serial = props.qube.serial.slice(-4);
  const battery100 = (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M8.2 3.4V6.6M10.6 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#56F000" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const battery80 = (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M8.2 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#56F000" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const battery40 = (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M5.8 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#FFF500" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  const battery20 = (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.4 3.4V6.6M2.6 1H11.4C11.8243 1 12.2313 1.16857 12.5314 1.46863C12.8314 1.76869 13 2.17565 13 2.6V3C13 3.10609 13.0421 3.20783 13.1172 3.28284C13.1922 3.35786 13.2939 3.4 13.4 3.4C13.5061 3.4 13.6078 3.44214 13.6828 3.51716C13.7579 3.59217 13.8 3.69391 13.8 3.8V6.2C13.8 6.30609 13.7579 6.40783 13.6828 6.48284C13.6078 6.55786 13.5061 6.6 13.4 6.6C13.2939 6.6 13.1922 6.64214 13.1172 6.71716C13.0421 6.79217 13 6.89391 13 7V7.4C13 7.82435 12.8314 8.23131 12.5314 8.53137C12.2313 8.83143 11.8243 9 11.4 9H2.6C2.17565 9 1.76869 8.83143 1.46863 8.53137C1.16857 8.23131 1 7.82435 1 7.4V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1Z" stroke="#FF2222" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  
  const batteries = { 20: battery20, 40: battery40, 80: battery80, 100: battery100, };
  const batteryKeyList = Object.keys(batteries).filter((key) => parseInt(key) > props.qube.battery_level); 
  const batteryKey = batteryKeyList.length ? batteryKeyList[0] : 20;
  const qube = props.qube;

  /**
   * 
   */
  const handleOnCopy = (id: string, setCopyState: (copied: boolean) => void) => {
    copy(id);
    setCopyState(true);
    setTimeout(() => setCopyState(false), 3000);
  };

  /**
   * 
   */
  const handleArm = () => {
    setArmed(props.qube.serial, !props.qube.armed, props.auth.token);
  };
    
  return (
    <QubeCardContainer tabIndex={0} className="gt_qube_card">
      <QubeModalHeader>
        <div>
          <QubeModalGoBackButton className="no-close-dropdown" onClick={props.onClose}>
            <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 9.67969L9 18.6797L10.4 17.1797L3 9.67969L10.4 2.17969L9 0.679688L0 9.67969Z" fill="#323030"/>
            </svg>
          </QubeModalGoBackButton>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2 13.1C16.2 13.404 16.032 13.668 15.776 13.804L9.45599 17.356C9.32799 17.452 9.16799 17.5 8.99999 17.5C8.83199 17.5 8.67199 17.452 8.54399 17.356L2.22399 13.804C2.09573 13.7366 1.9884 13.6353 1.91365 13.5112C1.8389 13.3871 1.79959 13.2449 1.79999 13.1V5.9C1.79999 5.596 1.96799 5.332 2.22399 5.196L8.54399 1.644C8.67199 1.548 8.83199 1.5 8.99999 1.5C9.16799 1.5 9.32799 1.548 9.45599 1.644L15.776 5.196C16.032 5.332 16.2 5.596 16.2 5.9V13.1ZM8.99999 3.22L4.23199 5.9L8.99999 8.58L13.768 5.9L8.99999 3.22ZM3.39999 12.628L8.19999 15.332V9.964L3.39999 7.268V12.628ZM14.6 12.628V7.268L9.79999 9.964V15.332L14.6 12.628Z" fill="#D30000"/>
            </svg>
          <QubeModalSerial>{serial}</QubeModalSerial>
        </div>
        <div>
          <QubeModalBattery>
            {batteries[batteryKey]}
            <span>{qube.battery_level}%</span>
          </QubeModalBattery>
        </div>
      </QubeModalHeader>

      <QubeModalQubeInformations>
        <QubePropertiesList>
          <QubeProperty>
            <QubePropertyStatus $checked={qube.online} />
            <QubePropertyLabel>{qube.online ? 'Online' : 'Offline' }</QubePropertyLabel>
          </QubeProperty>
        </QubePropertiesList>

        <QubeInfoTable>
          <tr>
            <td>Uptime</td>
            <td>{getUptime(qube.uptime_secs)}</td>
            <td></td>
          </tr>
          <tr>
            <td>Serial number</td>
            <td id="serial-cp">{qube.serial}</td>
            <td>
              {
                !copiedSerial ? (
                  <QubeModalCopyButton className="no-close-dropdown" onClick={() => handleOnCopy('serial-cp', setCopiedSerial)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 7.667C5 6.95967 5.28099 6.28131 5.78115 5.78115C6.28131 5.28099 6.95967 5 7.667 5H16.333C16.6832 5 17.03 5.06898 17.3536 5.20301C17.6772 5.33704 17.9712 5.53349 18.2189 5.78115C18.4665 6.0288 18.663 6.32281 18.797 6.64638C18.931 6.96996 19 7.31676 19 7.667V16.333C19 16.6832 18.931 17.03 18.797 17.3536C18.663 17.6772 18.4665 17.9712 18.2189 18.2189C17.9712 18.4665 17.6772 18.663 17.3536 18.797C17.03 18.931 16.6832 19 16.333 19H7.667C7.31676 19 6.96996 18.931 6.64638 18.797C6.32281 18.663 6.0288 18.4665 5.78115 18.2189C5.53349 17.9712 5.33704 17.6772 5.20301 17.3536C5.06898 17.03 5 16.6832 5 16.333V7.667Z" stroke="#595959" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M2.012 14.737C1.70534 14.5622 1.45027 14.3095 1.27258 14.0045C1.09488 13.6995 1.00085 13.353 1 13V3C1 1.9 1.9 1 3 1H13C13.75 1 14.158 1.385 14.5 2" stroke="#595959" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </QubeModalCopyButton>
                ) : (
                  <QubeModalCopied>Copied!</QubeModalCopied>
                )
              }
            </td>
          </tr>
          <tr>
            <td>Location</td>
            <td id="coords-cp">{qube.position.coordinates[1].toFixed(5)}, {qube.position.coordinates[0].toFixed(5)}</td>
            <td>
              {
                !copiedCoords ? (
                  <QubeModalCopyButton className="no-close-dropdown" onClick={() => handleOnCopy('coords-cp', setCopiedCoords)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 7.667C5 6.95967 5.28099 6.28131 5.78115 5.78115C6.28131 5.28099 6.95967 5 7.667 5H16.333C16.6832 5 17.03 5.06898 17.3536 5.20301C17.6772 5.33704 17.9712 5.53349 18.2189 5.78115C18.4665 6.0288 18.663 6.32281 18.797 6.64638C18.931 6.96996 19 7.31676 19 7.667V16.333C19 16.6832 18.931 17.03 18.797 17.3536C18.663 17.6772 18.4665 17.9712 18.2189 18.2189C17.9712 18.4665 17.6772 18.663 17.3536 18.797C17.03 18.931 16.6832 19 16.333 19H7.667C7.31676 19 6.96996 18.931 6.64638 18.797C6.32281 18.663 6.0288 18.4665 5.78115 18.2189C5.53349 17.9712 5.33704 17.6772 5.20301 17.3536C5.06898 17.03 5 16.6832 5 16.333V7.667Z" stroke="#595959" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M2.012 14.737C1.70534 14.5622 1.45027 14.3095 1.27258 14.0045C1.09488 13.6995 1.00085 13.353 1 13V3C1 1.9 1.9 1 3 1H13C13.75 1 14.158 1.385 14.5 2" stroke="#595959" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </QubeModalCopyButton>
                ) : (
                  <QubeModalCopied>Copied!</QubeModalCopied>
                )
              }
            </td>
          </tr>
        </QubeInfoTable>

        <QubeModalStatus className="no-close-dropdown" $armed={qube.armed} onClick={handleArm}>
          <label>Status</label>
          {
            props.isObserver ? (
              <span className="status">{qube.armed ? 'Armed' : 'Disarmed'}</span>
            ) : (
              <button>
                <span className="switch" />
                <span className="status">{qube.armed ? 'Armed' : 'Disarmed'}</span>
              </button>
            )
          }
        </QubeModalStatus>
      </QubeModalQubeInformations>
    </QubeCardContainer>
  );
};

export default QubeCard;
