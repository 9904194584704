import { useContext, useEffect } from 'react';
import NotificationStore from 'app/modules/notification/notification.context';
import {
  ToastComponent,
  ToastTitle,
  ToastText,
  ToastWrapper,
} from './toast.style';

const iconTypes: any = {
  error: (
    <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill="url(#paint0_linear)" />
      <path d="M17 27.5L27.5 17" stroke="white" strokeWidth="2" />
      <path d="M17 17L27.5 27.5" stroke="white" strokeWidth="2" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17.0646"
          y1="0"
          x2="17.0646"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F62D2D" />
          <stop offset="1" stopColor="#FF715E" />
        </linearGradient>
      </defs>
    </svg>
  ),
  success: (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#30D988" />
      <path d="M9 14.8L11.8571 18L19 10" stroke="white" strokeWidth="2" />
    </svg>
  ),
  loading: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        fill="#585d77d8"
        cy="50"
        stroke="#FFF"
        strokeWidth="10"
        r="40"
        strokeDasharray="190"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1.9048192771084336s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  ),
};

/**
 * 
 * @returns 
 */
const Toast = () => {
  const { state, dispatch } = useContext(NotificationStore);

  useEffect(() => {
    if (state.toast) {
      const timeout = state.toast?.timeout || 4000;
      setTimeout(() => dispatch({ type: 'CLOSE_TOAST' }), timeout);
    }
  }, [state.toast]);

  if (!state.toast) return null;

  return (
    <ToastWrapper>
      <ToastComponent>
        {iconTypes[`${state.toast?.type}`]}
        <div>
          <ToastTitle data-testid={`toast-type-${state.toast?.type}`}>{state.toast?.title}</ToastTitle>
          {state.toast?.text && <ToastText>{state.toast?.text}</ToastText>}
        </div>
      </ToastComponent>
    </ToastWrapper>
  );
};

Toast.defaultProps = {};

export default Toast;
