import { useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'qs';
import AccountStore from 'app/modules/account/account.context';
import Typograph from 'stories/type/typograph/typograph';
import FilledButton from 'stories/base/buttons/filled-button/filled-button';
import CodeInput from 'app/modules/account/login/components/code-input/code-input';
import { submit } from './project-code-modal.controller';
import {
  ProjectCodeModalWrapper,
  ProjectCodeModalContainer,
} from './project-code-modal.style';

/**
 * 
 * @returns 
 */
const ProjectCodeModal = () => {
  const accountContext = useContext(AccountStore);
  const history = useHistory();
  const location = useLocation();

  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const modal = useMemo(() => {
    const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    return query.modal;
  }, [location]);

  /**
   * 
   * @param e 
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit(e, {
      projectCode: code,
      dispatch: accountContext.dispatch,
      setError,
      history,
    })
  };

  const handleClose = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('modal');
    QueryString.stringify(url.searchParams, { addQueryPrefix: true });
    history.push(url.pathname + url.search);
  }

  if (modal !== 'project-code') {
    return null;  
  }
  
  return (
    <ProjectCodeModalWrapper onClick={handleClose}>
      <ProjectCodeModalContainer onClick={(e) => e.stopPropagation()}>
        <header>
          <Typograph type="headline6">Project Code</Typograph>
          <Typograph type="body2">You&apos;ll be directed to a different project </Typograph>

        </header>

        <form onSubmit={handleSubmit}>
          <CodeInput onChange={setCode} length={6} />
          {error && <Typograph type="body2">{error}</Typograph>}
          <FilledButton type="submit" text="Go to different project" />
        </form>
      </ProjectCodeModalContainer>
    </ProjectCodeModalWrapper>
  );
};

export default ProjectCodeModal;
