import { useState, useEffect, ReactNode } from 'react';
import { ToltipMessage, ToltipWrapper } from './toltip.style';

interface Props {
  children: ReactNode;
  text: string;
  active: boolean;
  hover?: boolean;
}

const Toltip = ({ children, text, active, hover }: Props) => {
  const [internalActive, setInternalActive] = useState<boolean>(active);
  const [mouseOverTimeout, setMouseOverTimeout] = useState<NodeJS.Timeout | null>(null);
  let timeout: NodeJS.Timeout | null = null;
  const timeToShow = 2500;
  const timeToHide = 4000;

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (internalActive) {
      timeout = setTimeout(() => setInternalActive(false), timeToHide);
    }
  }, [internalActive]);

  useEffect(() => {
    if (active) setInternalActive(active);
  }, [active]);

  return (
    <ToltipWrapper
      onMouseEnter={() => {
        if (mouseOverTimeout) {
          clearTimeout(mouseOverTimeout);
          setMouseOverTimeout(null);
        }
        
        if (hover && !active) {
          const moTimeout = setTimeout(() => {
            setInternalActive(true);
          }, timeToShow)

          setMouseOverTimeout(moTimeout);
        }
      }}
      onMouseLeave={() => {
        if (mouseOverTimeout) {
          clearTimeout(mouseOverTimeout);
          setMouseOverTimeout(null);
        }

        if (hover && !active) setInternalActive(false);
      }}
    >
      {internalActive ? <ToltipMessage className='toltip-message'>{text}</ToltipMessage> : null}
      {children}
    </ToltipWrapper>
  );
};

Toltip.defaultProps = {
  hover: true,
};

export default Toltip;
