const SAMPLE_RATE = 8000;
const INITIAL_GAIN = 0.009;

class PlayerController {
  // The start time displayed in the chart data, in tstamp
  private dataStartTime: number;
  // The actual audio data coming from qube
  private waveData: Float32Array;
  // The callback to set the audio bar time
  private setAudioBar: (position: Date) => void;
  // The callback to set when audio finishes
  private onEnded: () => void;
  // True if audio is current playing
  private playing: boolean;
  // The tstamp in milliseconds indicating when the audio starts
  // May be adjusted to an initial timeshift
  private startTime: number | undefined;
  // The pointer in milliseconds where the audio is now,
  // updated often if audio is playing
  private currentTime: number;
  // The setInterval stored result related to updating the current time
  private interval: ReturnType<typeof setInterval> | undefined;
  // The audio context
  private audioContext: AudioContext;
  // The active audio source
  private audioSource: AudioBufferSourceNode | undefined;
  // The gain node stored to adjust gain
  private gainNode: GainNode | undefined;
  // The gain value suplied to GainNode
  private gain: number;
  // This is set to true to prevent calling onEnded when currentTime changes
  // during play
  private preventOnEnded: boolean;

  constructor(
    dataStartTime:Date,
    waveData: Float32Array,
    setAudioBar: (position: Date) => void,
    onEnded: () => void,
  ) {
    this.dataStartTime = dataStartTime.getTime();
    this.waveData = waveData;
    this.setAudioBar = setAudioBar;
    this.onEnded = onEnded;
    this.currentTime = 0;
    this.playing = false;
    this.gain = INITIAL_GAIN;
    this.audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
    this.preventOnEnded = false;
    this.upSample();
  }

  public setGain(gain: number) {
    this.gain = gain;
    if (this.gainNode) {
      this.gainNode.gain.value = gain;
    }
  }

  public play() {
    if (this.playing) {
      this.preventOnEnded = true;
      // preventOnEnded will be set to false by onended() event below
      const doPlay = () => {
        if (!this.preventOnEnded) {
          this.play();
        } else {
          setTimeout(doPlay, 50);
        }
      }
      this.stop();
      setTimeout(doPlay, 1);
      return;
    }

    this.audioSource = this.audioContext.createBufferSource();

    // this.currentTime is in milliseconds, audioPosition is in samples
    const ratio = SAMPLE_RATE / 1000;
    const audioPosition = this.currentTime * ratio;
    const size = this.waveData.length - audioPosition;
    const audioData = this.waveData.slice(audioPosition);

    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = this.gain;

    const buffer = this.audioContext.createBuffer(2, size, SAMPLE_RATE);
    buffer.copyToChannel(audioData, 0);
    buffer.copyToChannel(audioData, 1);

    this.audioSource.loop = false;
    this.audioSource.buffer = buffer;

    this.audioSource
      .connect(this.gainNode)
      .connect(this.audioContext.destination);

    this.startTime = new Date().getTime() - this.currentTime;
    this.playing = true;

    this.audioSource.start()

    this.audioSource.onended = () => {
      this.stop();
      const elapsed = new Date().getTime() - this.startTime!;
      if (elapsed > this.waveData.length / ratio) {
        this.rewind();
      }
      if (this.preventOnEnded) {
        this.preventOnEnded = false;
      } else {
        this.onEnded();
      }
    };

    this.interval = setInterval(() => {
      this.currentTime = new Date().getTime() - this.startTime!;
      this.setBar();
    }, 125)
  }

  public stop() {
    if (!this.playing)
      return;
    clearInterval(this.interval!);
    this.audioSource!.stop();
    this.audioSource!.disconnect();
    this.playing = false;
  }

  public rewind() {
    this.currentTime = 0;
    this.setBar();
    if (this.playing)
      this.play();
  }

  public setTime(time: Date) {
    const shift = time.getTime() - this.dataStartTime;
    if (shift / 1000 > this.waveData.length / SAMPLE_RATE)
      return;
    this.currentTime = shift;
    if (this.playing)
      this.play();
    this.setBar();
  }

  private setBar() {
    this.setAudioBar(
      new Date(this.dataStartTime + this.currentTime)
    );
  }

  private upSample() {
    // Convert this.waveData from 4khz to 8khz
    const newData = new Float32Array(this.waveData.length * 2);
    const length = this.waveData.length;

    for (let i = 0; i < length - 1; i++) {
      newData[2 * i] = this.waveData[i];
      newData[2 * i + 1] = (this.waveData[i] + this.waveData[i + 1]) / 2;
    }

    newData[2 * length - 2] = this.waveData[length - 1];
    newData[2 * length - 1] = this.waveData[length - 1];

    this.waveData = newData;
  }

}

export default PlayerController;
