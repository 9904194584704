import { useState, useContext, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import AccountStore from '../account.context';
import { usePublicPage } from '../public-wrapper';
import GtLogo from './components/gt-logo/gt-logo';
import Typograph from 'stories/type/typograph/typograph';
import Input from 'stories/base/forms/input/input';
import PasswordInput from 'stories/base/forms/password-input/password-input';
import FilledButton from 'stories/base/buttons/filled-button/filled-button';
import TextButton from 'stories/base/buttons/text-button/text-button';
import CodeInput from './components/code-input/code-input';
import { submiProjectCodeLogin, submitLogin, verifyAuth } from './login.controller';
import {
  LoginContainer,
  LoginHeader,
  LoginMain,
  LoginForm,
  LoginFooter,
  LoginError,
} from './login.page.style';

/**
 * Login component
 * @returns
 */
const Login = () => {
  usePublicPage();
  
  // router
  const history = useHistory<RouteComponentProps['history']>();
  // context
  const accountContext = useContext(AccountStore);
  // states
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [projectCode, setProjectCode] = useState<string>('');
  const [defaultLoginError, setDefaultLoginError] = useState<string>('');
  const [guetLoginError, setGuestLoginError] = useState<string>('');
  const [verified, setVerified] = useState<boolean>(false);

  useEffect(() => {
    const token: string | null = window.localStorage.getItem('CDI@auth-token');
    verifyAuth(token, history, setVerified, accountContext.dispatch);
  }, []);

  /**
   * 
   */
  const handleForgotPassword = (e: MouseEvent) => {
    e.preventDefault();
    history.push('/forgot-password');
  };

  if (!verified) return null;

  const explanationTextToProjectCode = `Enter a Project Code to access your Project in Observer Mode.`;

  return (
    <LoginContainer data-testid="login">
      <LoginHeader>
        <GtLogo />
      </LoginHeader>

      <LoginMain>
        <header>
          <Typograph text="Welcome to GlobalTrack" type="headline6" />
        </header>

        {/* DEFAULT LOGIN FORM */}
        <LoginForm
          onSubmit={(e) => {
            e.preventDefault();
            submitLogin(
              e, 
              {
                email,
                password,
                dispatch: accountContext.dispatch,
                setError: setDefaultLoginError,
                history,
              },
            );
          }}
        >
          <Typograph type="headline6">
            <strong>Login</strong>
          </Typograph>

          <Typograph type="body1">
            Insert your <strong>email</strong> and <strong>password</strong>.
          </Typograph>

          {
            defaultLoginError && (
              <LoginError data-testid="login-email-error">
                <Typograph type="body2" data-testid="login-email-error">
                  {defaultLoginError}
                </Typograph>
              </LoginError>
            )
          }
          
          <Input
            id="email"
            error={defaultLoginError}
            noErrorText
            errorTestId="login-email-error"
            placeholder="Enter your email"
            value={email}
            onChange={({ target }: any) => setEmail(target.value)}
          />
          
          <PasswordInput
            id="password"
            error={defaultLoginError}
            noErrorText
            placeholder="Enter your password"
            visibilityTestId="login-password-visibility"
            value={password}
            onChange={({ target }: any) => setPassword(target.value)}
          />

          <FilledButton text="Enter" type="submit" />

          <footer>
            <TextButton text="Forgot your password?" onClick={handleForgotPassword}/>
          </footer>
        </LoginForm>

        <LoginForm
          onSubmit={(e) => {
            e.preventDefault();
            submiProjectCodeLogin(
              e, 
              {
                projectCode,
                dispatch: accountContext.dispatch,
                setError: setGuestLoginError,
                history,
              },
            );
          }}
        >
          <Typograph type="headline6">
            <strong>Project Code</strong>
          </Typograph>

          <Typograph type="body1" text={explanationTextToProjectCode} />

          {
            guetLoginError && (
              <LoginError data-testid="login-code-error">
                <Typograph type="body2" data-testid="login-code-error">
                  {guetLoginError}
                </Typograph>
              </LoginError>
            )
          }

          <CodeInput length={6} onChange={setProjectCode} />

          <FilledButton text="Verify" type="submit" />
        </LoginForm>
      </LoginMain>

      <LoginFooter>
        <Typograph type="body2">
          Trouble logging in? 
        </Typograph>
        <Typograph type="body2">
          Email us at <strong>globaltrack@pigging.com</strong>
        </Typograph>

        {/* <div>
          <TextButton text="Privacy & Terms" onClick={handlePrivacyAndTems}/>
          <TextButton text="Cookie Policy" onClick={handleCookiePolicy}/>
        </div> */}
      </LoginFooter>
    </LoginContainer>
  );
};

export default Login;
