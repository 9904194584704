/***************************************************************************
 * Generated by RxDjango on 2025-01-22 17:59 (UTC)                         *
 *                                                                         *
 * Based on all ContextChannel.as_asgi() calls in backend.asgi.application *
 *                                                                         *
 * This is expected to match inspection/channels.py                        *
 *                                                                         *
 *                          DO NOT EDIT THIS FILE                          *
 *                                                                         *
 * To rebuild it, run:                                                     *
 *                                                                         *
 *     ./manage.py makefrontend [inspection]                               *
 *                                                                         *
 ***************************************************************************/
import { ContextChannel } from '@rxdjango/react';

const SOCKET_URL = import.meta.env.VITE_SOCKET_URL.replace(/ws$/, '');

import { RunCascadeType } from './inspection.interfaces.d';


export class RunChannel extends ContextChannel<RunCascadeType> {

  anchor = 'inspection.serializers.RunCascadeSerializer';
  endpoint: string = 'ws/run/{run_id}/';

  args: { [key: string]: number | string } = {};

  baseURL: string = SOCKET_URL;

  constructor(run_id: number, token: string) {
    super(token);
    this.args['run_id'] = run_id;
  }

  model = {
    "inspection.serializers.RunCascadeSerializer": {
      "pipeline": "survey.serializers.PipelineSerializer",
      "trackingpoint_set": "inspection.serializers.TrackingPointCascadeSerializer",
      "estimation": "inspection.serializers.EstimationSerializer",
      "project": "project.serializers.ProjectSerializer",
      "users": "inspection.serializers.RunUserSerializer",
      "timepin_set": "inspection.serializers.TimePinSerializer",
      "forecast": "weather.serializers.ForecastSerializer",
      "survey": "survey.serializers.SurveySerializer",
      "logs": "inspection.serializers.ActionLogSerializer"
    },
    "survey.serializers.PipelineSerializer": {},
    "inspection.serializers.TrackingPointCascadeSerializer": {
      "passage": "inspection.serializers.PassageSerializer",
      "trackingpointnote_set": "inspection.serializers.TrackingPointNoteSerializer",
      "trigger_set": "detection.serializers.TriggerSerializer",
      "media_set": "survey.serializers.SurveyMediaSerializer"
    },
    "inspection.serializers.PassageSerializer": {},
    "inspection.serializers.TrackingPointNoteSerializer": {},
    "survey.serializers.SurveyMediaSerializer": {},
    "detection.serializers.TriggerSerializer": {
      "marks": "detection.serializers.TriggerMarkSerializer",
      "triggerseen_set": "detection.serializers.TriggerSeenSerializer"
    },
    "detection.serializers.TriggerMarkSerializer": {},
    "detection.serializers.TriggerSeenSerializer": {},
    "inspection.serializers.EstimationSerializer": {},
    "project.serializers.ProjectSerializer": {
      "customer": "customer.serializers.CustomerSerializer",
      "creator": "account.serializers.UserSerializer",
      "final_customer": "project.serializers.FinalCustomerSerializer",
      "participant_set": "account.serializers.ParticipantSerializer",
      "device_set": "detection.serializers.DeviceSerializer"
    },
    "customer.serializers.CustomerSerializer": {
      "company": "account.serializers.CompanySerializer",
      "qube_set": "qube.serializers.QubeDeviceSerializer",
      "project_set": "customer.serializers.ProjectSimpleSerializer"
    },
    "account.serializers.CompanySerializer": {},
    "qube.serializers.QubeDeviceSerializer": {},
    "customer.serializers.ProjectSimpleSerializer": {
      "device_set": "customer.serializers.DeviceSimpleSerializer"
    },
    "customer.serializers.DeviceSimpleSerializer": {},
    "project.serializers.FinalCustomerSerializer": {},
    "account.serializers.ParticipantSerializer": {
      "project": "account.serializers.ProjectLocalSerializer",
      "user": "account.serializers.UserSerializer"
    },
    "account.serializers.ProjectLocalSerializer": {},
    "account.serializers.UserSerializer": {},
    "detection.serializers.DeviceSerializer": {
      "qubedevice": "qube.serializers.QubeDeviceSerializer"
    },
    "survey.serializers.SurveySerializer": {
      "pipeline": "survey.serializers.PipelineSerializer",
      "surveypoint_set": "survey.serializers.SurveyPointSerializer",
      "surveyfile_set": "survey.serializers.SurveyFileSetSerializer",
      "surveyor": "survey.serializers.SurveyorSerializer",
      "survey_by": "account.serializers.CompanySerializer",
      "customer": "customer.serializers.CustomerSerializer"
    },
    "survey.serializers.SurveyFileSetSerializer": {},
    "survey.serializers.SurveyPointSerializer": {
      "surveyor": "survey.serializers.SurveyorSerializer",
      "media_set": "survey.serializers.SurveyMediaSerializer"
    },
    "survey.serializers.SurveyorSerializer": {},
    "inspection.serializers.ActionLogSerializer": {
      "logseen_set": "inspection.serializers.LogSeenSerializer"
    },
    "inspection.serializers.LogSeenSerializer": {},
    "inspection.serializers.RunUserSerializer": {},
    "inspection.serializers.TimePinSerializer": {},
    "weather.serializers.ForecastSerializer": {}
  };
}