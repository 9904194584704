import styled from  'styled-components';

export const ProjectCodeSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: 10px;
`;

export const ProjectCodeSettingsGroupLabelWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;

  > p {
    display: none;
  }
`;

export const ProjectCodeSettingsHeader = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  justify-items: start;
  gap: 4px;
`;

export const ProjectCodeSettingsTitle = styled.h2`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: center;

  color: #090808;
`;

export const ProjectCodeSettingsContent = styled.div`
  display: grid;
  gap: 16px;
`;

export const ProjectCodeSettingsGroup = styled.div<{ hide?: boolean }>`
  display: grid;
  grid-template-columns: auto;
  align-content: center;
  justify-items: start;
  gap: 8px;
  
  ${({ hide }) => hide && 'display: none;'}

  > .project-code-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;

    @media (min-width: 768px) {
      grid-template-columns: auto;

      > .change-project-wrapper {
        display: none;
      }
    }

    > .change-project-wrapper {
      margin-top: 8px;
      text-decoration: underline;
  
      > button {
        padding: 0;
      }
    }
  }

  > .caption {
    display: none;
  }
  
  @media (min-width: 768px) {
    grid-template-columns: auto;

    > .change-project-wrapper {
      display: none;  
    }

    > .caption {
      display: block;
    }
  }
`;

export const ProjectCodeSettingsGroupLabel = styled.label`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;

  color: #090808;

  span {
    font-weight: 400;
    opacity: 0.6;
  }
`;

export const ProjectCodeSettingsGroupSwitch = styled.div`
  display: flex;
  gap: 8px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 30px;
    border-radius: 24px;

    cursor: pointer;

    &.active {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      color: #5850EC;
      background-color: #5850EC4D;
    }

    &:not(.active) {
      border: 1px solid #A4ABB6;
      background-color: transparent;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: #4D5158;
    }
  }
`;

export const ProjectCodeSettingsGroupCode = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  margin-top: 8px;

  padding: 8px;
  align-items: center;
  justify-content: start;
  justify-items: start;

  border: 1px solid #A4ABB6;
  border-radius: 8px;
  background-color: #ececec;

  > span {
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    color: #323030;
  }

  > button {
    cursor: pointer;

    > span {
      background-color: #090808;
      color: #ffffff;
      border-radius: 4px;
      padding: 2px 4px;
      font-size: 12px;
    }
  }
`;
