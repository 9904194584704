import { useContext, useEffect, useMemo, useState } from 'react';
import { VictoryAxis, VictoryChart, VictoryTheme, VictoryLine } from 'victory';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import { getXYData, getTriggerTstamp } from 'app/modules/inspection/run/devices/desktop/components/qube-triggers-overlay/qube-triggers-overlay.controller';
import SettingsStore from 'app/modules/settings/settings.context';
import { TriggerType } from 'app/modules/detection/detection.interfaces';
import { formatTimezoneDate } from '@/lib/formatter';
import light from 'app/themes/light';
import { ChartName, Rangey1, Rangey2, Wrapper } from './magnetic-chart.style';
import { CustomContainer } from 'app/components/chart-files/custom-container';
import { MousePosition, ZoomDomain } from '../../charts';
import { formatDatum, formatTime } from '../../chart.handlers';
import Skeleton from 'app/components/skeleton/skeleton';
import PlayerController from './player-controller';

interface Props {
  trigger: TriggerType | undefined;
  zoomDomain: ZoomDomain | undefined;
  rangeY: [number, number] | undefined;
  rangeX: [Date, Date] | undefined;
  delta: number
  mousePosition: MousePosition | undefined;
  setZoomDomain: (domain: ZoomDomain | undefined) => void;
  setMousePosition: (position: MousePosition | undefined) => void;
  player?: PlayerController;
  audioBar?: Date;
  setRangeY: (y: [number, number]) => void;
  setRangeX: (x: [Date, Date]) => void;
  resetZoomChart: () => void;
  type: 'ac' | 'dc';
  width: number;
  height: number;
  title: string;
  fixed_scale?: boolean;
}

export interface MagneticData {
  x: Date;
  y: number;
}

/**
 *
 * @returns
 */
const MagneticChart = (props: Props) => {
  const styleContext = useContext(ThemeContext) as typeof light;
  const settingsContext = useContext(SettingsStore);
  const [magneticData, setMagnetic] = useState<{ x: MagneticData[], y: MagneticData[]}>();
  const [line, setLine] = useState<{x: Date, y: number}[]>();
  const [rangeY, setRangeY] = useState<[number, number]>();
  const [loading, setLoading] = useState<boolean>();
  const [mouseLabel, setMouseLabel] = useState<string[]>();
  const [fixedRangeY, setFixedRangeY] = useState<
    ([
      number | string,
      number | string
    ]) |
      undefined
    >(props.rangeY);


  const selectedRangeY = useMemo(() => {
    const rRangeY = props.fixed_scale ? fixedRangeY : rangeY;
    const selected: [number, number] = [
      rRangeY && rRangeY[0] !== '-' ? Number(rRangeY[0]) : 0,
      rRangeY && rRangeY[1] !== '-' ? Number(rRangeY[1]) : 0
    ];
    return selected;
  }, [props.fixed_scale, fixedRangeY, rangeY])


  useEffect(() => {
    props.resetZoomChart();
    props.setRangeY([0,0]);
    props.setRangeX([new Date(0),new Date(100000)]);
    return () => {
      props.resetZoomChart();
      props.setRangeY([0,0]);
      props.setRangeX([new Date(0),new Date(100000)]);
    }
  }, []);

  useEffect(() => {
    if (props.trigger) {
      const timezone = settingsContext.state.timezone?.id;

      getXYData(
        props.trigger,
        timezone,
        setMagnetic,
        setRangeY,
        selectedRangeY,
        props.setRangeX,
        setLoading,
        props.type,
      );
    }
  }, [props.trigger?.medias, props.type]);

  useEffect(() => {
    if (selectedRangeY) {
      props.setRangeY(selectedRangeY);
    }
  }, [selectedRangeY]);

  useEffect(() => {
    if (props.mousePosition?.x){
      const mouseLabel = ['',formatTime(settingsContext.state.timezone!, props.mousePosition?.x)];
      setMouseLabel(mouseLabel);
    }
  }, [props.mousePosition?.x]);

  useEffect(() => {
    if (magneticData) {
      const timezone = settingsContext.state.timezone?.id;
      const date = moment.tz(getTriggerTstamp(props.trigger), timezone || 'UTC')
      const firstAcxDate = magneticData.x[0]
      const lastAcxDate = magneticData.x[magneticData.x.length - 1]

      if (!firstAcxDate || !lastAcxDate) {
        setLine([]);
        return;
      }

      const xTriggerDate = date.toDate();
      if (selectedRangeY) {

        const line = [
          { x: xTriggerDate, y: selectedRangeY[0] },
          { x: xTriggerDate, y: selectedRangeY[1] },
        ];

        setLine(line);
      }
    }
  }, [magneticData, selectedRangeY]);

  return (
    <Skeleton loading={!!loading} width={`${props.width}px`} height={`${props.height}px`}>
      <Wrapper>
        <Rangey1
          onChange={({ target }) => {
            if (Number.isNaN(Number(target.value))) {
              return setFixedRangeY([fixedRangeY![0], '-'])
            } else if (target.value[0] === '-') {
              return setFixedRangeY([fixedRangeY![0], target.value]);
            } else if (target.value.slice(-1) === '.') {
              return setFixedRangeY([fixedRangeY![0], target.value]);
            }
            setFixedRangeY([fixedRangeY![0], Number(target.value)])}
          }
          value={fixedRangeY && fixedRangeY[1]}
          hidden={!props.fixed_scale}
          />
        <Rangey2
          onChange={({ target }) => {
            if (Number.isNaN(Number(target.value))) {
              return setFixedRangeY(['-', fixedRangeY![1]])
            } else if (target.value[0] === '-') {
              return setFixedRangeY([target.value, fixedRangeY![1]]);
            }else if (target.value.slice(-1) === '.') {
              return setFixedRangeY([target.value, fixedRangeY![1]]);
            }
            setFixedRangeY([Number(target.value), fixedRangeY![1]])
          }
        }
          value={fixedRangeY && fixedRangeY[0]}
          hidden={!props.fixed_scale}
        />
        <ChartName>{props.title}</ChartName>
        <VictoryChart
          width={props.width}
          height={props.height}
          padding={{top: 25, bottom: 30, right: 50, left: 50}}
          scale={{ x: "time" }}
          theme={VictoryTheme.material}
          containerComponent={
            <CustomContainer
              cursorLabel={({ datum }) => formatDatum({ timezone: settingsContext.state.timezone, datum })}
              zoomDimension="x"
              zoomDomain={{
                ...props.zoomDomain,
                y: selectedRangeY,
              }}
              onMouseMove={(data)=> props.setMousePosition(data)}
              onClick={(e) => { props.player?.setTime(e.x) }}
              allowPan={true}
              onZoomDomainChange={(domain) => {
                props.setZoomDomain(domain as unknown as { x: [Date, Date], y: [number, number] });
              }}
            />
          }
        >
          <VictoryAxis
            axisValue={0}
            tickFormat={() => ''}
            style={{
              axis: {
                stroke: styleContext.colors.neutra_400,
              },
            }}
          />
          <VictoryAxis
            axisValue={selectedRangeY ? selectedRangeY[0] : 0}
          />
          <VictoryAxis
            width={500}
            height={400}
            domain={{
              ...props.zoomDomain,
              y: selectedRangeY,
            }}
            dependentAxis
            tickCount={5}
            crossAxis={false}
          />
          <VictoryLine
            style={{
              data: { stroke: styleContext.colors.system_danger }
            }}
            data={magneticData?.x}
          />
          <VictoryLine
            style={{
              data: { stroke: styleContext.colors.primary_500 }
            }}
            data={magneticData?.y}
          />
          {props.trigger ? (
            <VictoryLine
              style={{
                data: {
                  stroke: styleContext.colors.neutra_400,
                  strokeWidth: 1,
                },
              }}
              data={line}
              key={`trigger-${props.trigger.id}`}
              labels={({ datum }) => (!selectedRangeY || (datum.y > selectedRangeY[0]) ? formatTimezoneDate({
                date: getTriggerTstamp(props.trigger),
                timezone: settingsContext.state.timezone?.id || '',
                format: 'HH:mm:ss.SSS',
              }) : '')}
            />
          ) : null}
          {props.mousePosition && selectedRangeY !== undefined ? (
            <VictoryLine
              key={`${props.title}-mouse-line`}
              style={{
                data: {
                  stroke: '#000',
                  strokeWidth: 1,
                },
                labels: {
                  fill: '#000',
                  padding: 15,
                  pointerEvents: 'none',
                  textAnchor: 'start',
                  backgroundColor: '#FFF',
                  stroke: '#FFF',
                }
              }}
              data={[
                { x: props.mousePosition.x, y: selectedRangeY[0] },
                { x: props.mousePosition.x, y: selectedRangeY[1] },
              ]}
              labels={mouseLabel}
            />
          ) : null}
          {props.audioBar && selectedRangeY !== undefined ? (
            <VictoryLine
              key={`${props.title}-audio-line`}
              style={{
                data: {
                  stroke: '#00ff00',
                  strokeWidth: 1,
                },
                labels: {
                  fill: '#000',
                  padding: 15,
                  pointerEvents: 'none',
                  textAnchor: 'start',
                  backgroundColor: '#FFF',
                  stroke: '#FFF',
                }
              }}
              data={[
                { x: props.audioBar, y: selectedRangeY[0] },
                { x: props.audioBar, y: selectedRangeY[1] },
              ]}
            />
          ) : null}
        </VictoryChart>
      </Wrapper>
    </Skeleton>
  );
};

MagneticChart.defaultProps = {
  width: 500,
  height: 230,
}

export default MagneticChart;
