import styled from 'styled-components';

export const Group = styled.div`
  width: 100%;
  position: relative;

  display: grid;
  gap: 8px;
`;

export const GroupLabel = styled.label<{ disabled: boolean }>`
  ${({ theme }) => theme.fonts.typograph.body2};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.neutra_300 : theme.colors.outline_on_surface)};
`;

export const GroupError = styled.p.attrs({ className: 'error' })`
  margin: 0;

  ${({ theme }) => theme.fonts.typograph.caption};
  color: ${({ theme }) => theme.colors.system_danger};
`;

export const GroupInputWrapper = styled.div<{ $iconAlignment?: string; $error?: string }>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  width: 100%;
  height: 36px;
  border-radius: 6px;
  border: 1px solid;
  border-color: ${({ theme, $error }) => ($error ? theme.colors.system_danger : theme.colors.neutra_300)};
  background-color: ${({ theme }) => theme.colors.surface_white};
  overflow: hidden;

  &:has(input:disabled) {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.neutra_100};
  }

  > span {
    color: ${({ theme }) =>  theme.colors.neutra_300};
    font-size: 22px;
    padding-left: ${({ $iconAlignment }) => ($iconAlignment === 'left' ? '8px' : '0')};
    padding-right: ${({ $iconAlignment }) => ($iconAlignment === 'right' ? '8px' : '0')};
  }

  > input {
    padding-left: ${({ $iconAlignment }) => ($iconAlignment === 'left' ? '8px' : '16px')};
    padding-right: ${({ $iconAlignment }) => ($iconAlignment === 'right' ? '8px' : '16px')};

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const GroupCountryCodeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.fonts.typograph.body2};

  background-color: ${({ theme }) => theme.colors.neutra_50};
  min-width: 40px;
  height: 100%;
  border: none;
  border-right: solid 1px ${({ theme }) => theme.colors.neutra_300};

  cursor: pointer;
  font-size: 13px;

  span {
    font-size: 14px;
  }
`;

export const GroupPhoneInput = styled.input`
  height: 100%;
  padding: 0px 16px;
  border: none;

  ${({ theme }) => theme.fonts.typograph.body2};

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    ${({ theme }) => theme.colors.body2};
    color: ${({ theme }) => theme.colors.neutra_300};
  }
`;


export const GroupCountryCodeDropdown = styled.div<{ open: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;

  width: 100%;
  height: 160px;
  
  display: ${({ open }) => (open ? 'grid' : 'none')};
  background-color: ${({ theme }) => theme.colors.surface_white};;
  border: solid 1px ${({ theme }) => theme.colors.neutra_300};
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.02);
  padding: 8px;

  grid-template-rows: auto 1fr auto;
  gap: 4px;
`;

export const GroupCountryCodeList = styled.ul`
  width: 100%;
  padding-inline-start: 0;
  overflow: auto;

  > li {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 4px;
    
    padding-top: 4px; 
    padding-bottom: 4px;

    ${({ theme }) => theme.fonts.typograph.body2};

    cursor: pointer;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.neutra_100};
    }

    figure {
      margin: 0;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      display: inline-block;
      width: 20px;
      margin-right: 8px;
    }
  }
`;

export const GroupCountryCodeSearchInput = styled.input`
  height: 32px;
  padding: 0px 16px;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colors.neutra_300};

  ${({ theme }) => theme.fonts.typograph.body2};

  &:focus {
    border: solid 1px ${({ theme }) => theme.colors.neutra_300};
    padding: 0px 16px;
    outline: none;
  }

  &::placeholder {
    ${({ theme }) => theme.colors.body2};
    padding: 0px 16px;
    color: ${({ theme }) => theme.colors.neutra_300};
  }

  &:active {
    border: solid 1px ${({ theme }) => theme.colors.neutra_300};
    padding: 0px 16px;  
  }

  &:focus-within {
    border: solid 1px ${({ theme }) => theme.colors.neutra_300};
    padding: 0px 16px;  
  }
`;

