import FingerprintJS from '@fingerprintjs/fingerprintjs';
import moment from 'moment-timezone';
import { RouteComponentProps } from 'react-router-dom';
import { verifySurveyAdmin } from '../private-wrapper.controller';
import { loginRequest, projectCodeLoginRequest, verifyToken } from 'app/services/auth.service';
import { loadAllProjects } from 'app/services/projects.service';
import { AccountDispatchType } from '../account.context.d';

interface SubmitLogin {
  email: string;
  password: string;
  dispatch(data: AccountDispatchType): void;
  setError(error: any): void;
  history: RouteComponentProps['history'];
}

interface SubmitProjectCodeLogin {
  projectCode: string;
  dispatch(data: AccountDispatchType): void;
  setError(error: any): void;
  history: RouteComponentProps['history'];
}

/**
 * 
 * @param e 
 * @param param1 
 * @returns 
 */
export const submitLogin = async (
  e: React.FormEvent<HTMLFormElement>,
  { password, email, setError, dispatch }: SubmitLogin
) => {
  e.preventDefault();
  let loginResponse: any;

  try {
    loginResponse = await loginRequest({ password, email });
  } catch (err: any) {
    if (err?.response?.status === 401) {
      setError('Invalid email or password.');
      return;
    } else {
      setError('Sorry unexpected error, try again in some minutes.');
    }
  }

  await verifySurveyAdmin(
    loginResponse?.data?.user,
    loginResponse?.data?.token,
    dispatch
  );

  window.localStorage.setItem('CDI@auth-token', loginResponse?.data?.token);
  window.localStorage.setItem(
    'CDI@auth-email',
    loginResponse?.data?.user?.email
  );

  await loadAllProjects(loginResponse?.data.token);
  
  dispatch({
    type: 'LOGIN',
    data: loginResponse?.data,
  });
};

/**
 * 
 * @param e 
 * @param param1 
 * @returns 
 */
export const submiProjectCodeLogin = async (
  e: React.FormEvent<HTMLFormElement>,
  { projectCode, setError, dispatch }: SubmitProjectCodeLogin
) => {
  e.preventDefault();
  let loginResponse: any;
  const fpPromise = await FingerprintJS.load();
  const response = await fpPromise.get();
  const fingerprint = response.visitorId;
  const timezone = moment.tz.guess();
  const unexpectedError = 'Sorry unexpected error, try again in some minutes.';

  try {
    loginResponse = await projectCodeLoginRequest(projectCode, fingerprint, timezone);
  } catch (err: any) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      let msg = err.response.data || unexpectedError;
      msg = msg[msg.length - 1] !== '.' ? `${msg}.` : msg;
      setError(msg);
      return;
    } else {
      setError(unexpectedError);
    }
  }

  await verifySurveyAdmin(
    loginResponse?.data?.user,
    loginResponse?.data?.token,
    dispatch
  );

  window.localStorage.setItem('CDI@auth-token', loginResponse?.data?.token);
  window.localStorage.setItem(
    'CDI@auth-email',
    loginResponse?.data?.user?.email
  );

  await loadAllProjects(loginResponse?.data.token);

  dispatch({
    type: 'LOGIN',
    data: loginResponse?.data,
  });
};

export const verifyAuth = async (
  token: string | null,
  history: any,
  setVerified: (verified: boolean) => void,
  dispatch: (data: AccountDispatchType) => void
) => {
  try {
    await verifyToken(token);
  } catch (err) {
    dispatch({
      type: 'LOGOUT',
    });

    setVerified(true);
    return;
  }

  history.push(`/projects`);

  setVerified(true);
};
