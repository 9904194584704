// external imports
import { formatTimezoneDate } from '@/lib/formatter';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// context
import SettingsStore from 'app/modules/settings/settings.context';
import ParticipantList from '../participant-list/participant-list';
// interfaces
import { CompanyType, RunType } from 'app/interfaces/inspection.interfaces';
// components
import RunCard from '../run-card/run-card';
import SecondaryButton from '../secondary-button/secondary-button';
import {
  Card,
  CollapseButton,
  Header,
  RunsAmount,
  ProjectInfoWrapper,
  ParticipantsWrapper,
  RunsWrapper,
  TitleWrapper,
  NextRunLabel,
  ScheduledWrapper,
} from './project-card.style';

interface ParticipantType {
  id: string;
  avatar: string;
  first_name: string;
  last_name: string;
  email: string;
}

interface CardProps {
  name: string;
  id: string;
  completedPoints: number;
  qttRuns: number;
  index: number;
  totalPoints: number;
  nextLocation: any;
  company: CompanyType;
  participants: ParticipantType[];
  nextEta: any;
  runs: RunType[];
  description: string;
  usingLineStat: boolean;
  usingQube: boolean;
}

/**
 *
 * @returns
 */
const ProjectCard = ({
  name,
  qttRuns,
  id,
  runs,
  participants,
  usingLineStat,
  usingQube,
  index,
}: CardProps) => {
  const history = useHistory();
  const settingsContext = useContext(SettingsStore);
  const [showList, setShowList] = useState(false);
  const [orderedRuns, setOrderedRuns] = useState<RunType[]>([]);

  useEffect(() => {
    let haveActiveRuns = false;
    runs?.forEach((r) => {
      if (r.launched && !r.is_finished) haveActiveRuns = true;
    });
    if (haveActiveRuns) setShowList(true);

    const sortedRuns = [...runs];

    const runsArr = sortedRuns.sort((a: RunType, b: RunType) => {
      const aDate = new Date(`${a.scheduled_launch_at}`).getTime();
      const bDate = new Date(`${b.scheduled_launch_at}`).getTime();
      return aDate - bDate;
    });
    setOrderedRuns(runsArr);
  }, [runs]);

  const startsOn = formatTimezoneDate({
    date: `${orderedRuns[0]?.scheduled_launch_at || ''}`,
    timezone: settingsContext.state.timezone?.id || '',
    format: 'MM/DD',
  });

  const alreadyStarted = !!orderedRuns[0]?.launch_time

  const endsAt = formatTimezoneDate({
    date: `${orderedRuns[orderedRuns.length - 1]?.scheduled_launch_at || ''}`,
    timezone: settingsContext.state.timezone?.id || '',
    format: 'MM/DD',
  });

  return (
    <Link id={`projects-page-project-card-${index}`} data-testid={`projects-page-project-card-${index}`} to={`/projects/${id}`}>
      <Card>
        <Header>
          <ProjectInfoWrapper>
            <TitleWrapper>
              <SecondaryButton
                customStyle={`
                  font-family: Inter;
                  font-style: normal;
                  font-size: 13px;
                  width: auto;
                  max-width: 100%;
                  font-weight: 500;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  color: #1a1e36;
                `}
                testId={`projects-page-project-card-project-button`}
                handleClick={(e) => {
                  e.preventDefault();
                  history.push(`/projects/${id}`);
                }}
              >
                {name}
              </SecondaryButton>
              <CollapseButton
                hideButton={!runs?.length}
                showList={showList}
                onClick={(e) => {
                  e.preventDefault();
                  setShowList(!showList);
                }}
              >
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.86824 5.48057C4.48435 6.15239 3.51565 6.15239 3.13176 5.48057L0.854936 1.49614C0.47399 0.829482 0.955357 3.37812e-07 1.72318 4.04937e-07L6.27682 8.03029e-07C7.04464 8.70154e-07 7.52601 0.829483 7.14506 1.49614L4.86824 5.48057Z"
                    fill="#1A1F36"
                  />
                </svg>
                <RunsAmount>
                  {qttRuns === 1 ? `${qttRuns} run` : `${qttRuns} runs`}
                </RunsAmount>
              </CollapseButton>
            </TitleWrapper>
            {orderedRuns[0]?.scheduled_launch_at ? (
              <ScheduledWrapper>
                <NextRunLabel>
                  <strong>{alreadyStarted ? 'Started at:' : 'Starts on:'}</strong> {startsOn}
                </NextRunLabel>
                <NextRunLabel>
                  <strong>Ends on:</strong> {endsAt}
                </NextRunLabel>
              </ScheduledWrapper>
            ) : null}
          </ProjectInfoWrapper>
          <ParticipantsWrapper>
            <ParticipantList participants={participants} />
          </ParticipantsWrapper>
        </Header>
        <RunsWrapper show={showList}>
          {runs?.map((r) => (
            <RunCard
              key={r.id}
              run={r}
              usingLineStat={usingLineStat}
              usingQube={usingQube}
              hideMenu={true}
              customStyle={`
                background-color: #F7F8F9;
                border-radius: 0;
                border: none;
                border-top: 1px solid #e7ebee;
                > header {
                  > button {
                    font-size: 13px;
                  }
                }
              `}
            />
          ))}
        </RunsWrapper>
      </Card>
    </Link>
  );
};

export default ProjectCard;
