import styled from 'styled-components';

export const RegisterContainer = styled.section`
  @media (min-width: 1024px) {
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 0;
  }
`;

export const RegisterHeader = styled.header`
  padding: 22px 40px;
  border-bottom: 1px solid #a4abb6;
  
  img {
    height: 28px;
  }
`;

export const RegisterForm = styled.form`
  min-width: 316px;
  max-width: 550px;

  display: grid;
  gap: 16px;

  border: 1px solid #A4ABB6;
  border-radius: 8px;
  padding: 24px;

  fieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 16px;

    > :nth-child(1),
    > :nth-child(4) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  footer {
    display: flex;
    justify-content: center;

    button {
      color: #363945;

      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
    }
  }
`;

export const RegisterError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px;
  border-radius: 8px;
  background: #FDE8E8;
  color: #C92A2A;
`;

export const RegisterMain = styled.main`
  padding: 40px;

  display: grid;
  grid-gap: 16px;
  align-content: start;
  justify-content: center;

  @media (min-width: 1024px) {
    > header {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

export const RegisterFooter = styled.footer`
  text-align: center;
  padding: 24px;

  > div {
    display: flex;
    justify-content: center;
    gap: 16px;

    margin-top: 45px;
    padding: 26px;

    border-top: 1px solid #A4ABB6;

    button {
      text-decoration: underline;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      color: #363945;
    }
  }
`;


export const RegistrationTerms = styled.p`
  margin-top: 16px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color: #686c7b;

  > a {
    color: #5850ec;
  }
`;

