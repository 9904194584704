import { ChangeEvent, useMemo, useState } from 'react';
import FilledButton from '../../buttons/filled-button/filled-button';
import { countries } from './phone-input.model';
import {   
  Group,
  GroupCountryCodeButton,
  GroupCountryCodeDropdown,
  GroupCountryCodeSearchInput,
  GroupCountryCodeList,
  GroupError,
  GroupInputWrapper,
  GroupLabel,
  GroupPhoneInput,
} from './phone-input.style';

interface Props {
  onCountryChange: (country: string, countryCallingCode: string) => void;
  onPhoneChange: (e: ChangeEvent<HTMLInputElement>) => void;
  phone?: string;
  country: string;
  error?: string;
  label?: string;
  disabled?: boolean;
}

/**
 *
 * @returns
 */
const PhoneInput = ({
  onCountryChange,
  onPhoneChange,
  phone,
  country = 'US',
  error,
  label,
  disabled,
}: Props) => {
  const [countryCodeSearch, setCountryCodeSearch] = useState<string>('');
  const [countryCodeListOpened, setCountryCodeListOpened] = useState<boolean>(false);
  const filteredCountries = useMemo(() => {
    if (!countryCodeSearch) return countries;
    const filterExp = new RegExp(`${countryCodeSearch}`, 'gi');
    return countries.filter((c) => filterExp.test(c.countryCallingCode) ||
                                         filterExp.test(c.countryCode) ||
                                         filterExp.test(c.countryNameEn) ||
                                         filterExp.test(c.countryNameLocal));
  }, [countryCodeSearch]);
  const selectedCountry = useMemo(() => {
    return countries.find((c) => c.countryCode === country);
  }, [country]);

  /**
   *
   * @returns
   */
  const renderOptions = () =>
    filteredCountries.map((country) => (
      <li
        key={country.countryCode}
        className="gt_phone_input"
        onClick={() => {
          onCountryChange(country.countryCallingCode, country.countryCode);
          setCountryCodeListOpened(false);
          setCountryCodeSearch('');
        }}
      >
        <figure>
          <img src={`/assets/icons/country-flags/${country.countryCode.toLowerCase()}.svg`} />
        </figure> 
        
        <span>
          {country.countryNameEn}
        </span>
        
        <strong>
          +{country.countryCallingCode}
        </strong>
      </li>
    ));

  return (
    <Group>
      {label ? <GroupLabel disabled={!!disabled}>{label}</GroupLabel> : null}
      <GroupInputWrapper
        $error={error}
        tabIndex={1}
        onBlur={(e) => {
          if (e.relatedTarget?.classList.contains('gt_phone_input')) return;
          if (e.target?.classList.contains('gt_phone_input')) return;
          setCountryCodeListOpened(false)
        }}
      >
        <GroupCountryCodeButton type="button" onClick={() => setCountryCodeListOpened(true)}>
          <span className="material-icons-outlined">add</span>
          {selectedCountry?.countryCallingCode}
        </GroupCountryCodeButton>
        <GroupPhoneInput value={phone} type="tel" onChange={onPhoneChange} />
      </GroupInputWrapper>

      <GroupCountryCodeDropdown
        open={countryCodeListOpened}
        className="gt_phone_input"
      >
        <GroupCountryCodeSearchInput
          type="text"
          value={countryCodeSearch}
          placeholder="Search Country Code"
          className="gt_phone_input"
          onChange={(e) => {
            setCountryCodeSearch(e.target.value);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />

        <GroupCountryCodeList>
          {renderOptions()}
        </GroupCountryCodeList>

        <FilledButton
          onClick={() => {
            setCountryCodeListOpened(false)
            setCountryCodeSearch('');
          }}
          text="Cancel"
        />
      </GroupCountryCodeDropdown>

      {error ? <GroupError>{error}</GroupError> : null}
    </Group>
  );
};

export default PhoneInput;
