import { useContext, useState } from "react";
import InspectionStore from "app/modules/inspection/inspection.context";
import { MobileProjectCodeContainer } from "./mobile-project-code.style";

/**
 * 
 * @returns 
 */
const MobileProjectCode = () => {
  const inspectionContext = useContext(InspectionStore);
  const [copied, setCopied] = useState(false);
  const projectCode = inspectionContext.run?.project?.guest_code;
  const guestsEnabled = inspectionContext.run?.project?.guests_enabled;

  if (!guestsEnabled) {
    return null;
  }


  /**
   *  Copy the project code to the clipboard
   */
  const handleCopy = () => {
    const element = document.getElementById('guest-code');
    if (!projectCode || !element) return;
    navigator.clipboard.writeText(projectCode);
    
    const range = document.createRange();
    range.selectNodeContents(element);
    
    const selection = window.getSelection();
    if (!selection) return;    
    selection.removeAllRanges();
    selection.addRange(range);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 4000);
  };
  
  return (
    <MobileProjectCodeContainer>
      <label>Project Code</label>
      <span id="guest-code">{projectCode}</span>
      <button onClick={handleCopy}>
        {
          !copied ? (
            <svg width="15" height="15" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.512 14.737C2.20534 14.5622 1.95027 14.3095 1.77258 14.0045C1.59488 13.6995 1.50085 13.353 1.5 13V3C1.5 1.9 2.4 1 3.5 1H13.5C14.25 1 14.658 1.385 15 2M5.5 7.667C5.5 6.95967 5.78099 6.28131 6.28115 5.78115C6.78131 5.28099 7.45967 5 8.167 5H16.833C17.1832 5 17.53 5.06898 17.8536 5.20301C18.1772 5.33704 18.4712 5.53349 18.7189 5.78115C18.9665 6.0288 19.163 6.32281 19.297 6.64638C19.431 6.96996 19.5 7.31676 19.5 7.667V16.333C19.5 16.6832 19.431 17.03 19.297 17.3536C19.163 17.6772 18.9665 17.9712 18.7189 18.2189C18.4712 18.4665 18.1772 18.663 17.8536 18.797C17.53 18.931 17.1832 19 16.833 19H8.167C7.81676 19 7.46996 18.931 7.14638 18.797C6.82281 18.663 6.5288 18.4665 6.28115 18.2189C6.03349 17.9712 5.83704 17.6772 5.70301 17.3536C5.56898 17.03 5.5 16.6832 5.5 16.333V7.667Z" stroke="#5850EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          ) : (
            <span>Copied!</span>
          )
        }
      </button>
    </MobileProjectCodeContainer>
  );
};

export default MobileProjectCode;