import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../avatar/avatar';
import Skeleton from '../skeleton/skeleton';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import {
  BreadcrumbList,
  BreadcrumbsWrapper,
  BreadcrumbItem,
  LogoutButton,
  EmailLabel,
  MobileDropdown,
  DropdownList,
  Button,
} from './breadcrumbs.style';

export interface Breadcrumb {
  id?: string;
  text: string;
  href: string;
  loading?: boolean;
}

/**
 *
 * @param routes
 * @param onClick
 * @returns
 */
const renderBreadcrumbs = (routes: Breadcrumb[]) =>
  routes.map((route) => (
    <BreadcrumbItem key={route.href}>
      <Skeleton
        loading={route.loading || false}
        width="80px"
        height="16px"
        inline
      >
        <Link id={route.id} to={route.href}>{route.text}</Link>
      </Skeleton>
    </BreadcrumbItem>
  ));

interface BreadcrumbsProps {
  routes: Breadcrumb[];
  customStyle?: string;
  withoutAvatar?: boolean;
}

/**
 *
 * @param param0
 * @returns
 */
const Breadcrumbs = ({
  routes,
  customStyle,
  withoutAvatar,
}: BreadcrumbsProps) => {
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [dropdown, setDropdown] = useState(false);

  return (
    <BreadcrumbsWrapper data-testid="breadcrumbs-header" customStyle={customStyle}>
      <BreadcrumbList>{renderBreadcrumbs(routes)}</BreadcrumbList>
      {!withoutAvatar ? (
        <MobileDropdown>
          {auth.user.avatar ? (
            <Button
              onClick={() => setDropdown(!dropdown)}
              tabIndex={0}
              onBlur={(event: any) => {
                if (event.relatedTarget?.id === 'gt-navigation-dropdown')
                  return;
                setDropdown(false);
              }}
            >
              <Avatar
                src={auth.user.avatar}
                size={28}
                firstName={auth.user.first_name}
                lastName={auth.user.last_name}
                email={auth.user.email}
              />
            </Button>
          ) : (
            <svg
              width="28"
              height="28"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setDropdown(!dropdown)}
              style={{
                borderRadius: '50%',
                border: 'solid 2px #ffffff',
                boxShadow: dropdown ? '0px 0px 0px 2px #5850ec' : '',
              }}
              tabIndex={0}
              onBlur={(event: any) => {
                if (event.relatedTarget?.id === 'gt-navigation-dropdown')
                  return;
                setDropdown(false);
              }}
            >
              <circle cx="18" cy="18" r="18" fill="white" />
              <mask
                id="mask0_963_831"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <circle cx="18" cy="18" r="18" fill="white" />
              </mask>
              <g mask="url(#mask0_963_831)">
                <circle cx="18" cy="46.9999" r="24" fill="#B8C0C6" />
              </g>
              <circle cx="18" cy="14.9998" r="5" fill="#5D6C77" />
            </svg>
          )}
          <DropdownList
            tabIndex={0}
            id="gt-navigation-dropdown"
            hide={!dropdown}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {
              auth?.user?.is_guest ? (
                <EmailLabel>{auth?.user?.email} (Guest)</EmailLabel>
              ) : (
                <EmailLabel>
                  {auth?.user?.first_name} {auth?.user?.last_name}
                </EmailLabel>
              )
            }
            <LogoutButton
              id="gt-navigation-dropdown"
              onClick={(e) => {
                e.stopPropagation();
                window.localStorage.removeItem('CDI@auth-email');
                window.localStorage.removeItem('CDI@auth-token');
                accountContext.dispatch({ type: 'LOGOUT' });
                window.location.href = '/';
              }}
            >
              Log out
            </LogoutButton>
          </DropdownList>
        </MobileDropdown>
      ) : null}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
