import { useState } from 'react';
import {
  Route,
  Switch,
  RouteComponentProps,
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  ForgotPasswordContainer,
  ForgotPasswordHeader,
  ForgotPasswordMain,
  ForgotPasswordForm,
  ForgotPasswordError,
  ForgotPasswordFooter,
  ForgotPasswordSuccess,
} from './forgot-password.page.style';
import { sendEmail, changePassword } from './forgot-password.controller';
import { ErrorType } from './forgot-password.page.d';
import Typograph from 'stories/type/typograph/typograph';
import FilledButton from 'stories/base/buttons/filled-button/filled-button';
import Input from 'stories/base/forms/input/input';
import GtLogo from '../login/components/gt-logo/gt-logo';
import PasswordInput from 'stories/base/forms/password-input/password-input';

/**
 * ForgotPassword component
 * @returns
 */
const ForgotPassword = () => {
  const history = useHistory<RouteComponentProps['history']>();
  const { token } = useParams<{ token: string }>();
  // context
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmationPassword, setConfirmationPassword] = useState<string>('');
  const [error, setError] = useState<ErrorType>({});
  const emailRxp = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

  /**
   * function to handle password change
   * @param param0 
   */
  const handlePasswordChange = ({ target }) => {
    if (target.value && target.value.length < 6) {
      setError({
        ...error,
        password: 'Password must be at least 6 characters long',
      });
    } else {
      setError({
        ...error,
        password: '',
      });
    }

    setPassword(target.value);
  };

  /**
   * function to handle confirm password change
   * @param param0 
   */
  const handleConfirmPasswordChange = ({ target }) => {
    setConfirmationPassword(target.value);

    if (target.value !== password) {
      setError({
        ...error,
        confirmationPassword: 'Passwords do not match',
      });

      return;
    }

    setError({
      ...error,
      confirmationPassword: '',
    });
  };

  /**
   * function to handle email change
   * @param param0 
   */
  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
    if (target.value && !emailRxp.test(target.value)) {
      setError({
        email: 'Invalid e-mail.',
      });
    } else {
      setError({
        email: undefined,
      });
    }
  };

  return (
    <ForgotPasswordContainer data-testid="login">
      <ForgotPasswordHeader>
        <GtLogo />
      </ForgotPasswordHeader>

      <Switch>
        {/* FORM TO SEND RESET PASSWORD EMAIL */}
        <Route exact path="/forgot-password">
          <ForgotPasswordMain>
            <header>
              <Typograph text="Forgot your Password?" type="headline6" />
            </header>

            <ForgotPasswordForm
              onSubmit={(e) => {
                e.preventDefault();
                sendEmail(
                  email,
                  () => history.push('/forgot-password/sent'),
                  setError
                );
              }}
            >
              <Typograph type="headline6">
                <strong>Reset Password</strong>
              </Typograph>

              <Typograph type="body1">
                Insert your <strong>e-mail</strong> and we will send you a link to reset your password.
              </Typograph>

              {
                error.general && (
                  <ForgotPasswordError data-testid="login-email-error">
                    <Typograph type="body2" data-testid="login-email-error">
                      {error.general}
                    </Typograph>
                  </ForgotPasswordError>
                )
              }
              
              <Input
                id="email"
                error={error.email}
                errorTestId="forgot-password-email-error"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
              />

              <FilledButton text="Send e-mail" disabled={!email || !emailRxp.test(email)} type="submit" />
            </ForgotPasswordForm>
          </ForgotPasswordMain>
        </Route>

        {/* SUCESS ROUTE */}
        <Route exact path="/forgot-password/sent">
          <ForgotPasswordMain>
            <header>
              <Typograph text="Forgot Password" type="headline6" />
            </header>

            <ForgotPasswordSuccess data-testid="forgot-password-email-sent">
              <Typograph text="E-mail sent." type="subtitle1" />


              <Typograph type="body2" data-testid="forgot-password-email-sent-text">
                A link has been sent to your email. Access your email and click on the link to reset your password.
              </Typograph>
            </ForgotPasswordSuccess>
          </ForgotPasswordMain>
        </Route>

        {/* FORM TO CHANGE PASSWORD */}
        <Route path="/forgot-password/:token">
          <ForgotPasswordMain>
            <header>
              <Typograph text="Password Reset" type="headline6" />
            </header>
            
            <ForgotPasswordForm
              onSubmit={(e) => {
                e.preventDefault();
                changePassword(token, password, () => history.push('/'));
              }}
            >
              <Typograph type="headline6">
                <strong>Reset your password</strong>
              </Typograph>

              <Typograph type="body1">
                Insert your <strong>new password</strong> and confirm it.
              </Typograph>

              {
                error.general && (
                  <ForgotPasswordError data-testid="login-email-error">
                    <Typograph type="body2" data-testid="login-email-error">
                      {error.general}
                    </Typograph>
                  </ForgotPasswordError>
                )
              }
              
              <PasswordInput
                id="password"
                error={error.password}
                placeholder="Enter your new password"
                value={password}
                onChange={handlePasswordChange}
              />
              
              <PasswordInput
                id="cofirmation-password"
                error={error.confirmationPassword}
                placeholder="Confirm your new password"
                value={confirmationPassword}
                onChange={handleConfirmPasswordChange}
              />

              <FilledButton text="Reset passoword" disabled={!!(password && password !== confirmationPassword)} type="submit" />
            </ForgotPasswordForm>
          </ForgotPasswordMain>
        </Route>
      </Switch>

      <ForgotPasswordFooter>
        <Typograph type="body2">
          Having a trouble to accesss? 
        </Typograph>
        <Typograph type="body2">
          Email us at <strong>globaltrack@pigging.com</strong>
        </Typograph>
      </ForgotPasswordFooter>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
