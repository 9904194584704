import { ThemeProvider } from 'styled-components';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import light from 'app/themes/light';

// css global imports
import '../assets/css/reset.css';
import 'material-icons/iconfont/outlined.css';
import 'material-icons/iconfont/filled.css'
import 'material-symbols/outlined.css';
import 'material-symbols/rounded.css';

// matomo
import MatomoTracking from './matomo';

// components
import PrivateWrapper from 'app/modules/account/private-wrapper';

// modules
import AccountModule from './modules/account/account.module';
import CustomerModule from './modules/customer/customer.module';
import InspectionModule from './modules/inspection/inspection.module';
import InvitationModule from './modules/invitation/invitation.module';
import ProjectsModule from './modules/projects/projects.module';
import SurveyModule from './modules/survey/survey.module';

// providers
import { AccountProvider } from './modules/account/account.context';
import { NotificationProvider } from './modules/notification/notification.context';
import { SettingsProvider } from './modules/settings/settings.context';
import ProjectCodeModal from './components/project-code-modal/project-code-modal';

/**
 * init provider and routing the application
 * @returns React.Component
 */
export const App = () => (
  <ThemeProvider theme={light}>
    <Router>
      <MatomoTracking />
      <SettingsProvider>
        <NotificationProvider>
          <AccountProvider>
            <Route path="/" component={AccountModule} />
            <Route path="/invitations" component={InvitationModule} />

            <PrivateWrapper>
              <Route path="/runs/:id" component={InspectionModule} />
              <Route path="/surveys" component={SurveyModule} />
              <Route path="/customer" component={CustomerModule} />
              <Route path="/" component={ProjectsModule} />

              <ProjectCodeModal />
            </PrivateWrapper>
          </AccountProvider>
        </NotificationProvider>
      </SettingsProvider>
    </Router>
  </ThemeProvider>
);

export default App;
