import { useEffect } from 'react';
import { useState } from 'react';
import Checkbox from '../checkbox/checkbox';
import Radio from '../radio/radio';
import {
  Button,
  DropdownList,
  LabelItem,
  Group,
  GroupTitle,
  Label,
  Wrapper,
} from './dropdown-button.style';

/**
 *
 * @param checks
 * @returns
 */
const renderChecks = (checks: any[], onClick: any, index: number) =>
  checks?.map((check: any) => (
    <li key={check.id}>
      <Checkbox
        checked={check.checked}
        text={check.label}
        onClick={() => onClick(check, index)}
      />
    </li>
  ));

/**
 *
 * @param radios
 * @param onClick
 * @returns
 */
const renderRadios = (radios: any[], onClick: any, index: number) => 
  radios?.map((radio: any) => (
    <li key={radio.id}>
      <Radio
        testId={radio.id}
        selected={radio.selected}
        text={radio.label}
        onClick={() => onClick(radio, index)}
      />
    </li>
  ));

/**
 *
 * @param labels
 * @param onClick
 * @returns
 */
const renderLabels = (labels: any[], onClick: any, index: number) =>
  labels.map((label: any) => (
    <LabelItem key={label.id}>
      <Label onClick={() => onClick(label, index)}>{label.text}</Label>
    </LabelItem>
  ));

// renders types
const renders: any = {
  label: renderLabels,
  checkbox: renderChecks,
  radio: renderRadios,
};

/**
 *
 * @param groups
 * @returns
 */
const renderGroups = (
  groups: any[],
  groupTitles: string[],
  onClick: any,
  type: string
) =>
  groups?.map((checks: any[], index: number) => (
    <li key={index} id="index">
      {groupTitles[index] ? (
        <GroupTitle>{groupTitles[index]}</GroupTitle>
      ) : null}
      <Group $type={type}>{renders[type](checks, onClick, index)}</Group>
    </li>
  ));

/**
 *
 * @param param0
 * @returns
 */
const DropdownButton = ({
  text,
  title,
  groupTitles,
  groups,
  onClick,
  type,
  customStyle,
  show,
  hideButton,
  onShow,
  icon,
  testId,
  circular,
  selectedId,
}: any) => {
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    if (show !== dropdown) setDropdown(show);
  }, [show]);

  useEffect(() => {
    if (show !== dropdown && onShow) onShow(dropdown);
  }, [dropdown]);

  return (
    <Wrapper
      data-testid={testId}
      title={title}
      $hideButton={hideButton}
      $circular={circular}
      onBlur={(event: any) => {
        if (event.relatedTarget?.id === 'gt_dropdown') return;
        setDropdown(false);
      }}
      $customStyle={customStyle}
    >
      <input data-testid="hidden" className='hidden' value={selectedId} />
      <Button onClick={() => setDropdown(!dropdown)} title={text}>
        {icon ? null : text}
        {icon && <span className="material-icons-outlined">{icon}</span>}
      </Button>
      
      <DropdownList tabIndex={0} id="gt_dropdown" $hide={!dropdown}>
        {renderGroups(groups, groupTitles, onClick, type)}
      </DropdownList>
    </Wrapper>
  );
};

DropdownButton.defaultProps = {
  type: 'label',
  groupTitles: [],
};

export default DropdownButton;
